import React, { Fragment } from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
	MP_CALL,
	MP_BASIC_CALL,
	MP_ERRAND,
	MP_NONE,
	MM_WORKFLOW,
	MM_SEARCH,
	MM_SEARCHANSWERS,
	MM_STATISTICS,
	MM_EXPORT,
	MM_ADMINISTRATION,
	MM_MESSAGE,
	EXECUTIVE_REPORT,
	CREATE_REPORT,
	CONFIGURE_TIME,
	SCHEDULED_REPORT,
	LIVE_REPORT_EMAIL,
	LIVE_REPORT_CHAT,
	LIVE_REPORT_VOICE,
	LIVE_REPORT_OVERVIEW,
	MM_EXPORT_CALL,
	MM_LIVE_REPORTS,
	SEARCH_SIDEBAR_RESULT_TXT_LIMIT
} from '../../common/v5/constants';
import { AS_CONFIRM_YES, AS_UNKNOWN } from '../../common/v5/callConstants';
import {
	withDisableableOnClick
	, withIdAttachedOnClick
	, withUnmountWhenHidden
} from '../../reactcomponents/hocs';
import { MenuItem } from '../../common/v5/menu';
import SidebarSettingsList from '../../reactcomponents/SidebarSettingsList';
import ToggleSwitch, {
	ChatToggleSwitch
} from '../../components/v5/ToggleSwitch';
import { Tooltip } from 'reactstrap';
import { ChatSourceConfigCtnr } from '../../common/v5/headerCtnr.js';
import { AgentNotification, AgentMenu, AgentStatusChanger } from '../../common/v5/header';
import HeaderSearch from '../../components/v5/HeaderSearch';
import { CallIcon } from '../../reactcomponents/CallPad';
import SipStatus from '../../reactcomponents/SipStatus';
import ProfilePhoto from '../../reactcomponents/ProfilePhoto';
import styled from 'styled-components';
import {
	centionGreen
	, centionRed
	, centionYellow
} from '../../styles/_variables'
import Popper from 'popper.js';
import { withCancellableAction } from '../../containers/hocs';

const isIE = /*@cc_on!@*/false || !!document.documentMode;
const isEdge = !isIE && !!window.StyleMedia;
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
if (isChrome || isEdge) {
	// see: https://github.com/twbs/bootstrap/issues/23590
	Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;
}

const SidebarButtonsListBase = props =>
	<ul className="sidebar-buttons-list" {...props} />;

export const SidebarButtonsList = withUnmountWhenHidden(SidebarButtonsListBase);

const PlusButtonWrapperBase = props => <li className={props.disabled ? "disabled" : ""} {...props} />;

const PlusButtonWrapper = withUnmountWhenHidden(PlusButtonWrapperBase);

const PlusButtonBase = ({ collapseSideBar, call, disabled, onClick, title, ...props }) => {
	let icon = "icon-new-errand";
	if (call) {
		icon = "icon-make-call";
	}
	let btn = <button type="button" className={classNames("btn-transparent", { disabled: disabled })} disabled={disabled} onClick={onClick} {...props}>
		<i className={icon}></i>
		<span className="setting-label">{props.children}</span>
	</button>
	if (collapseSideBar) {
		btn = <div onClick={onClick} className={disabled ? "disabled" : ""} title={title}><i className={icon}></i></div>
	}
	return btn;
}

export const PlusButton = withIdAttachedOnClick(PlusButtonBase);

const PlusErrand = props => (
	<PlusButton title={I("Create errand")} data-qa-id="QA_showManualErrand" id={MP_ERRAND} {...props}>
		{I('Create errand')}
	</PlusButton>
);

const PlusChatOrCall = ({ chat, ...props }) => {
	let text;
	if (chat) {
		text = I('Create chat');
	} else {
		text = I('Make call');
	}
	return (
		<PlusButton call={!chat} data-qa-id="QA_showCallErrand" {...props}>
			{text}
		</PlusButton>
	);
};

const PlusCall = ({ chat, ...props }) =>
	<PlusChatOrCall id={MP_CALL} title={I("Make call")} {...props} />;

const CallToggleSwitchBase = ({ accept, onClick, ...props }) => (
	<ToggleSwitch
		id="call-styler"
		name="call"
		label={I("Call")}
		checked={accept === AS_CONFIRM_YES}
		handleToggle={onClick}
		{...props}
	/>
);

const CallToggleSwitch = compose(
	withUnmountWhenHidden
	, withDisableableOnClick
)(CallToggleSwitchBase);

const tooltipWrapperStyle = { position: "inherit" };

const SideBarDropdownWrapper = ({ show, title, link, className, children, ...props }) => (
	<div className="collapse-tooltip-menu" hidden={!show}>
		<ul className="collapse-menu-list">
			<li className={classNames("collapse-tooltip-title-wrapper", { active: show })}>
				{
					link ? <Link title={title} to={link ? link : "#"} className="tooltip-nav-title">{title}</Link> : <span>{title}</span>
				}
			</li>
		</ul>
	</div>

)

export const SideBarDropdownWrapperWithTooltip = ({ show, onToggle, target, collapsed, autohide, title, link, className, tooltipCustomPos, customTopPos, children, ...props }) => (
	<Tooltip
		autohide={autohide}
		className={classNames("c3-sidebar-nav-tooltip", { collapsed: collapsed })}
		innerClassName={className}
		hideArrow={true}
		container={"body"}
		isOpen={show}
		placement={tooltipCustomPos ? tooltipCustomPos : "right-start"}
		target={target}
		style={{ top: customTopPos + "px" }}
		modifiers={{ preventOverflow: { enabled: false } }}
		toggle={onToggle}>
		<div className="collapse-tooltip-menu" hidden={!show}>
			<ul className="collapse-menu-list">
				<li className={"collapse-tooltip-title-wrapper active"} hidden={!title}>
					<a href={link} className="tooltip-nav-title">{title}</a>
				</li>
				{children}
			</ul>
		</div>
	</Tooltip>
)

const HOCMenuItem = withCancellableAction('onClick')(MenuItem);

export class IconizeSideBarMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showCollapsedAdminMenu: false,
			showCollapsedStatMenu: false,
			showCollapsedSearchMenu: false,
			showCollapsedSearchMenuWithInput: false,
			showCollapsedNotif: false,
			activatedTooltip: "",
			showLiveReportMenu: false,
			showExportMenu: false
		}
		this.handleShowAdminMenuList = this.handleShowAdminMenuList.bind(this);
		this.handleClickAdminMenu = this.handleClickAdminMenu.bind(this);
		this.handleShowStatMenuList = this.handleShowStatMenuList.bind(this);
		this.handleShowSearchMenuList = this.handleShowSearchMenuList.bind(this);
		this.loadMainMenu = this.loadMainMenu.bind(this);
		this.handleMouseOver = this.handleMouseOver.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.changeMainMenu = this.changeMainMenu.bind(this);
		this.changeView = this.changeView.bind(this);
		this.handleKeyUpSearchBox = this.handleKeyUpSearchBox.bind(this);
		this.handleSearchButton = this.handleSearchButton.bind(this);
		this.handleShowSearchMenuWithInput = this.handleShowSearchMenuWithInput.bind(this);
		this.handleShowNotifResult = this.handleShowNotifResult.bind(this);
		this.handleShowLiveReports = this.handleShowLiveReports.bind(this);
		this.handleClickLiveReportMenu = this.handleClickLiveReportMenu.bind(this);
		this.handleShowExportMenu = this.handleShowExportMenu.bind(this);
	}
	componentWillUnmount() {
		this.setState({
			activatedTooltip: ""
		});
	}
	getMenuIcon(id) {
		let icon = "";
		switch (id) {
			case MM_WORKFLOW:
				icon = "icon-errands";
				break;
			case MM_SEARCH:
				icon = "icon-search";
				break;
			case MM_SEARCHANSWERS:
			case "workflow.searchanswers":
				icon = "icon-search-answers";
				break;
			case MM_STATISTICS:
				icon = "icon-stats";
				break;
			case MM_EXPORT:
				icon = "icon-export";
				break;
			case MM_LIVE_REPORTS:
				icon = "icon-live";
				break;
			default:
				icon = "";
				break;
		}
		return icon;
	}
	handleShowAdminMenuList() {
		this.setState({ showCollapsedAdminMenu: !this.state.showCollapsedAdminMenu });
	}
	handleShowStatMenuList() {
		this.setState({ showCollapsedStatMenu: !this.state.showCollapsedStatMenu });
	}
	handleShowSearchMenuList() {
		this.setState({ showCollapsedSearchMenu: !this.state.showCollapsedSearchMenu });
	}
	handleShowSearchMenuWithInput() {
		this.setState({ showCollapsedSearchMenuWithInput: !this.state.showCollapsedSearchMenuWithInput });
	}
	handleShowNotifResult() {
		this.setState({ showCollapsedNotif: !this.state.showCollapsedNotif });
	}
	handleClickAdminMenu(url, label, id) {
		if (this.props.onChangePage) {
			this.props.onChangePage(url, id);
		}
	}
	handleMouseOver(id) {
		this.setState({ activatedTooltip: id });
	}
	handleMouseLeave() {
		this.setState({ activatedTooltip: "" });
	}
	changeView(page) {
		this.props.onChangeView(page);
	}
	changeMainMenu(id, link) {
		this.props.onLinkChange(id, link)
	}
	handleKeyUpSearchBox(e) {
		if (this.props.disableActiveSearch == false ||
			(this.props.disableActiveSearch == true && e.key == "Enter")) {
			this.props.handleKeyUpSearchBox(e, this.props.disableActiveSearch);
		}
	}
	handleSearchButton(e) {
		if (this.props.disableActiveSearch == true) {
			this.props.handleSearchButton(e, this.props.searchText);
		}
	}
	handleShowLiveReports() {
		this.setState({ showLiveReportMenu: !this.state.showLiveReportMenu });
	}
	handleShowExportMenu() {
		this.setState({ showExportMenu: !this.state.showExportMenu });
	}
	handleClickLiveReportMenu(view) {
		this.props.onOpenLiveReportsAndRedirectView(view);
	}
	loadMainMenu() {
		const p = this.props;
		const {
			systemReportselected
			, customReportselected
			, defaultSystemReport
			, defaultCustomReport
			, onNavigateSideMenu
		} = p
		const mainMenuList = menu.leftMenu;
		let menuList = [];
		let notifMsgLen = p.notification.uiData.noticeCount;

		if (p.currentPage !== MM_WORKFLOW) {
			menuList.push(
				<div id="side-notif-menu" className="collapse-menu" key={"collapse-side-menu"}>
					{
						notifMsgLen > 0 ?
							<a href="#" data-notices-count={notifMsgLen} data-qa-id="header-notif" data-dropdown-target="notices-dropdown" className="with-notices"><i className="icon-bell"></i></a>
							: <i className="icon-bell"></i>
					}
					<SideBarDropdownWrapperWithTooltip show={this.state.showCollapsedNotif && notifMsgLen > 0} className="collapse-tooltip-menu notif-side-menu-collapse" autohide={false} onToggle={this.handleShowNotifResult} collapsed={true}
						target={"side-notif-menu"} >
						<li data-tooltip data-qa-id="notif_side_menu">
							<AgentNotification
								messages={p.notification.messages}
								onToggleArrow={p.onToggleArrow}
								onSetNotificationTab={p.onSetNotificationTab}
								openAnnouncement={p.openAnnouncement}
								onShowAllNotification={p.onShowAllNotification}
								noticeCount={notifMsgLen}
								uiData={p.uiData}
								agentTimezoneOffset={p.agentTimezoneOffset}
								openLastErrandInThread={p.OpenLastErrandInThread}
								openCollaReplyPanel={p.openCollaReplyPanel}
								openErrand={p.openErrand}
								openMessage={p.openMessage}
								openStatistics={p.openStatistics}
								openAccounts={p.openAccounts}
								forSidebar={true}
							/>
						</li>
					</SideBarDropdownWrapperWithTooltip>
				</div>
			);
		}

		let showAllStats = true;
		let showExecutiveStatsOnly = false;
		let showAgentStatsOnly = false;

		if(features["admin.show-executive-report"] && !features["cention-reports"]) {
			showExecutiveStatsOnly = true;
			showAllStats = false;
		}
		if(features["agent-data-overview-statistics"] && !features["cention-reports"]) {
			showAgentStatsOnly = true;
			showAllStats = false;
		}

		for (let i = 0; i < mainMenuList.length; i++) {
			let menuItem = mainMenuList[i];
			if (menuItem.id === MM_WORKFLOW) {
				menuList.push(<li id={"list-side-menu-" + i} className="collapse-menu" hidden={!this.props.showWorkflowMenu} data-qa-id={"collapse-mm-" + menuItem.id} key={"collapse-mm-" + menuItem.id} onMouseLeave={this.handleMouseLeave}>
					<a title={menuItem.label} onClick={(e) => this.changeMainMenu(menuItem.id,menuItem.link)}><i className={this.getMenuIcon(menuItem.id)} /></a>
					<SideBarDropdownWrapperWithTooltip show={this.state.activatedTooltip == menuItem.id} collapsed={true} className="collapse-tooltip-menu" title={menuItem.label} autohide={true} link={menuItem.link} target={"list-side-menu-" + i} onToggle={(e) => this.handleMouseOver(menuItem.id)} />
				</li>);
			} else if (menuItem.id !== MM_ADMINISTRATION) {
				if (menuItem.id === MM_STATISTICS && p.currentPage === MM_STATISTICS) {
					menuList.push(
						<li id="collapse-stat-menu"
							className={classNames(
								"collapse-menu stats",
								{ active: (p.activeView !== LIVE_REPORT_EMAIL && p.activeView !== LIVE_REPORT_VOICE && p.activeView !== LIVE_REPORT_CHAT && p.activeView !== LIVE_REPORT_OVERVIEW) }
							)}
							key={"collapse-mm-" + menuItem.id}>
							<i className={this.getMenuIcon(menuItem.id)} />
							<SideBarDropdownWrapperWithTooltip show={this.state.showCollapsedStatMenu} collapsed={true} className="collapse-tooltip-menu" autohide={false} onToggle={this.handleShowStatMenuList} target={"collapse-stat-menu"} title={I("Statistics")}>
								{showAllStats &&
								<Fragment>
									<li data-tooltip data-qa-id="exec_report_link" className={p.activeView === EXECUTIVE_REPORT ? "current" : ""} onClick={(e) => this.changeView(EXECUTIVE_REPORT)}>
										<a>{I("Executive Report")}</a>
									</li>
									<Fragment>
										<li data-qa-id="system_report_link" className={systemReportselected ? "current" : ""} title={I("System Report")} onClick={(e) => onNavigateSideMenu(defaultSystemReport.Id, false)}>
											<a title={I("System Report")}>{I("System Report")}</a>
										</li>
										<li data-qa-id="custom_report_link" className={customReportselected ? "current" : ""} title={I("Custom Report")} onClick={(e) => onNavigateSideMenu(defaultCustomReport.Id, true)}>
											<a title={I("Custom Report")}>{I("Custom Report")}</a>
										</li>
									</Fragment>
									<li id={"list-side-menu-" + CREATE_REPORT} data-qa-id="create_report_link" className={p.activeView === CREATE_REPORT ? "current" : ""} title={I("Create reports")} onClick={(e) => this.changeView(CREATE_REPORT)}>
										<a title={I("Create reports")}>{I("Create reports")}</a>
									</li>
									<li data-qa-id="schedule_report_link" className={p.activeView === SCHEDULED_REPORT ? "current" : ""} title={I("Scheduled reports")} onClick={(e) => this.changeView(SCHEDULED_REPORT)}>
										<a title={I("Scheduled reports")}>{I("Scheduled reports")}</a>
									</li>
									<li data-qa-id="config_time_link" className={p.activeView === CONFIGURE_TIME ? "current" : ""} title={I("Configure Time")} onClick={(e) => this.changeView(CONFIGURE_TIME)}>
										<a title={I("Configure Time")}>{I("Configure Time")}</a>
									</li>
								</Fragment>
								}
								{showExecutiveStatsOnly &&
									<li data-tooltip data-qa-id="exec_report_link" className={p.activeView === EXECUTIVE_REPORT ? "current" : ""} onClick={(e) => this.changeView(EXECUTIVE_REPORT)}>
										<a>{I("Executive Report")}</a>
									</li>
								}
								{showAgentStatsOnly &&
									<li data-qa-id="system_report_link" className={systemReportselected ? "current" : ""} title={I("System Report")} onClick={(e) => onNavigateSideMenu(defaultSystemReport.Id, false)}>
										<a title={I("System Report")}>{I("System Report")}</a>
									</li>
								}
							</SideBarDropdownWrapperWithTooltip>
						</li>
					);
				} else if (menuItem.id === MM_STATISTICS) {
					menuList.push(<li id={"list-side-menu-" + i} className={classNames("collapse-menu", { active: p.currentPage === menuItem.id })} data-qa-id={"collapse-mm-" + menuItem.id} key={"collapse-mm-" + menuItem.id} onMouseLeave={this.handleMouseLeave}>
						<a title={menuItem.label} onClick={(e) => this.changeMainMenu(menuItem.id,menuItem.link)}><i className={this.getMenuIcon(menuItem.id)} /></a>
						<SideBarDropdownWrapperWithTooltip autohide={true} collapsed={true} show={this.state.activatedTooltip == menuItem.id} onToggle={(e) => this.handleMouseOver(menuItem.id)} className="collapse-tooltip-menu" target={"list-side-menu-" + i} title={menuItem.label} link={menuItem.link} />
					</li>);
				} else if (menuItem.id === MM_LIVE_REPORTS) {
					const liveReportSubMenu = menuItem.sub;
					let submenus = [];
					if(liveReportSubMenu.length > 0) {
						liveReportSubMenu.map(sub => {
							submenus.push(
								<li data-tooltip data-qa-id={sub.id+"_livereport_menu"} key={sub.id+"_livereport_menu"} className={p.activeView === sub.id ? "current" : ""} onClick={() => this.handleClickLiveReportMenu(sub.id)} >
									<a>{sub.label}</a>
								</li>
							)
						});
					}
					menuList.push(
						<li id="collapse-stat-menu"
							className={classNames(
								"collapse-menu stats",
								{ active: p.currentPage === menuItem.id }
							)}
							key={"collapse-mm-" + menuItem.id}>
							<a title={menuItem.label} href={menuItem.link} data-foo="foobar"><i className={this.getMenuIcon(menuItem.id)} /></a>
							<SideBarDropdownWrapperWithTooltip show={this.state.showLiveReportMenu} collapsed={true} className="collapse-tooltip-menu" autohide={false} onToggle={this.handleShowLiveReports} target={"collapse-stat-menu"} title={menuItem.label} link={menuItem.link} >
								<Fragment>
									{submenus}
								</Fragment>
							</SideBarDropdownWrapperWithTooltip>
						</li>
					);
				} else if (menuItem.id === MM_SEARCH && p.currentPage === MM_SEARCH) {
					menuList.push(
						<li id="collapse-search-menu" className="collapse-menu search active" key={"collapse-mm-" + menuItem.id}>
							<i className={this.getMenuIcon(menuItem.id)} />
							<SideBarDropdownWrapperWithTooltip show={this.state.showCollapsedSearchMenu} collapsed={true} className="collapse-tooltip-menu" autohide={false} onToggle={this.handleShowSearchMenuList} target={"collapse-search-menu"} title={I("Search")}>
								{this.props.children}
							</SideBarDropdownWrapperWithTooltip>
						</li>
					);
				} else if (menuItem.id === MM_SEARCH && p.currentPage !== MM_SEARCH) {
					menuList.push(
						<li id="side-search-menu-collapse"
							className={classNames("collapse-menu", { active: p.currentPage === MM_SEARCH }, { nowf: p.currentPage !== MM_WORKFLOW }, { show: this.state.showCollapsedSearchMenuWithInput })}
							key={"collapse-side-search"}>
							<a title={menuItem.label} onClick={(e) => this.changeMainMenu(menuItem.id,menuItem.link)}><i className={this.getMenuIcon(menuItem.id)} /></a>
							<SideBarDropdownWrapperWithTooltip show={this.state.showCollapsedSearchMenuWithInput} collapsed={true} autohide={false} onToggle={this.handleShowSearchMenuWithInput}
								className={classNames("collapse-tooltip-menu search-side-menu-collapse", { expand: p.showResultBox })}
								target={"side-search-menu-collapse"} >
								<li data-tooltip data-qa-id="search_side_menu" className={"current"}>
									<HeaderSearch
										isSearching={p.isSearching}
										searchResults={p.searchResults}
										searchText={p.searchText}
										showResultBox={p.showResultBox}
										onKeyDown={p.handleKeyDownSearchBox}
										onKeyUp={this.handleKeyUpSearchBox}
										onChange={p.handleChangeSearchBox}
										onSearchButton={this.handleSearchButton}
										handleHeaderDD={p.handleHeaderSearchDD}
										openErrand={p.openErrandFromSearch}
										withHoverForInput={true}
										resultTextLimit={SEARCH_SIDEBAR_RESULT_TXT_LIMIT}
										key="side-search-collapsed"
									/>
								</li>
							</SideBarDropdownWrapperWithTooltip>
						</li>
					);
				} else if (menuItem.id === MM_EXPORT) {
					menuList.push(
						<li data-qa-id="mm_collapsed_menu_link"
							id="collapse-exports-menu"
							key={"collapse-mm-" + MM_EXPORT + "-stat"}
							className={classNames(
								"collapse-menu",
								{
									active: (p.currentPage === MM_EXPORT || p.currentPage === MM_EXPORT_CALL)
								}
							)}
							title={I("Exports")}
						>
							<span onClick={(e) => this.changeMainMenu(menuItem.id, menuItem.link)}><i className={this.getMenuIcon(menuItem.id)}></i></span>
							<SideBarDropdownWrapperWithTooltip show={this.state.showExportMenu} collapsed={true} className="collapse-tooltip-menu" autohide={false}
								onToggle={this.handleShowExportMenu} target={"collapse-exports-menu"} title={I("Exports")}>
								{
									features['sip-enabled'] && <ul hidden={!this.state.showExportMenu} style={{ padding: '10px 10px 0px 10px' }}>
										{
											menuItem.sub.map((subMenuItem, i) => {
												return (
													<li data-qa-id={"export_subs_link_" + i} key={"export_subs_link_" + i} className={p.currentPage === subMenuItem.id ? "current" : ""} onClick={(e) => this.changeMainMenu(subMenuItem.id, subMenuItem.link)}>
														<a title={subMenuItem.label} >{subMenuItem.label}</a>
													</li>
												);
											})
										}
									</ul>
								}
							</SideBarDropdownWrapperWithTooltip>
						</li>
					);
				} else if (menuItem.id !== "") {
					if (menuItem.id !== "liveReports") {
						menuList.push(<li id={"list-side-menu-" + i} className={classNames("collapse-menu", { active: p.currentPage === menuItem.id })} data-qa-id={"collapse-mm-" + menuItem.id} key={"collapse-mm-" + menuItem.id} onMouseLeave={this.handleMouseLeave}>
							<a title={menuItem.label} onClick={(e) => this.changeMainMenu(menuItem.id, menuItem.link)}><i className={this.getMenuIcon(menuItem.id)} /></a>
							<SideBarDropdownWrapperWithTooltip autohide={true} collapsed={true} show={this.state.activatedTooltip == menuItem.id} onToggle={(e) => this.handleMouseOver(menuItem.id)} className="collapse-tooltip-menu" target={"list-side-menu-" + i} title={menuItem.label} link={menuItem.link} />
						</li>);
					}
				}
			}
		}
		return menuList;
	}
	render() {
		const p = this.props;
		const adminMenuList = menu.adminMenu.sub;
		return <ul className="collapse-wfmenu">
				{!this.props.onlyShowAdminMenu &&
					this.loadMainMenu()
				}
				{
					(adminMenuList && adminMenuList.length > 0) &&
					<li id="collapse-admin-menu" className={classNames("collapse-menu admin", { show: this.state.showCollapsedAdminMenu })}>
						<a><i className="icon-settings" /></a>
						<SideBarDropdownWrapperWithTooltip show={this.state.showCollapsedAdminMenu} collapsed={true} className={classNames("collapse-tooltip-menu admin", { imsg: p.currentPage === MM_MESSAGE, search: p.currentPage === MM_SEARCH })} autohide={false} target={"collapse-admin-menu"} onToggle={this.handleShowAdminMenuList} title={I("Administration")}>
							{
								adminMenuList.map((menuItem, i) => (
									<HOCMenuItem
										key={i}
										item={menuItem}
										active={false}
										onClick={this.handleClickAdminMenu}
									/>
								))
							}
						</SideBarDropdownWrapperWithTooltip>
					</li>
				}
			</ul>
	}
}

//SidebarIcon with tooltip when collapsed mode is ON.
export class SidebarIconItem extends React.Component {
	constructor(props) {
		super(props);
		this.handleOnClick = this.handleOnClick.bind(this);
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.state = {
			activatedTooltip: ""
		}
	}
	handleMouseEnter(id) {
		this.setState({ activatedTooltip: id });
	}
	handleMouseLeave() {
		this.setState({
			activatedTooltip: ""
		});
	}
	handleOnClick() {
		if (this.props.onClick) {
			this.props.onClick(this.props.id);
		}
	}
	render() {
		const { id, title, icon, linkTo, className, 'data-qa-id': dataQAId, onClick, children } = this.props;
		const tooltipWrapperStyle = { display: "flex", flexDirection: "column" };
		return <li key={"list-side-menu-" + id} onMouseEnter={(e) => this.handleMouseEnter(id)} onMouseLeave={this.handleMouseLeave} id={"list-side-menu-" + id} data-qa-id={dataQAId} style={tooltipWrapperStyle} className={className}>
			<Link title={title} to={linkTo ? linkTo : "#"} onClick={this.handleOnClick} >
				<i className={icon}></i>
			</Link>
			<SideBarDropdownWrapper show={this.state.activatedTooltip == id} title={title} link={linkTo ? linkTo : "#"} />
		</li>
	}
}

export class SidebarIconItemBasic extends React.Component {
	constructor(props) {
		super(props);
		this.handleOnClick = this.handleOnClick.bind(this);
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.state = {
			activatedTooltip: ""
		}
	}
	handleMouseEnter(id) {
		this.setState({ activatedTooltip: id });
	}
	handleMouseLeave() {
		this.setState({
			activatedTooltip: ""
		});
	}
	handleOnClick() {
		if (this.props.onClick) {
			this.props.onClick(this.props.id);
		}
	}
	render() {
		const { id, title, icon, linkTo, className, 'data-qa-id': dataQAId, onClick, children } = this.props;
		const tooltipWrapperStyle = { display: "flex", flexDirection: "column" };
		return <li key={"list-side-menu-" + id} onMouseEnter={(e) => this.handleMouseEnter(id)} onMouseLeave={this.handleMouseLeave} id={"list-side-menu-" + id} data-qa-id={dataQAId} style={tooltipWrapperStyle} className={className}>
			<ul>{children}</ul>
			{title &&
				<SideBarDropdownWrapper show={this.state.activatedTooltip == id} title={title} />
			}
		</li>
	}
}

const StyledUserStatusWithAvatar = styled.span`
	display: contents;
	&:after {
		display: ${props => props.disconnected ? 'none' : 'inline-block'};
		width: 11px;
		height: 11px;
		border-radius: 30px;
		background-color: #ccc;
		content: '';
		// left: ${props => props.collapsed ? '20px' : '33px'};
		position: absolute;
		top: 0px;
		right: 8px;
		transition: background-color .2s ease;
		background: ${props => props.status == "available" ? centionGreen : props.status == "away" ? centionYellow : centionRed};
	}
`

class SideBarButtons extends React.Component {
	constructor(props) {
		super(props);
		this.handlePickUpNext = this.handlePickUpNext.bind(this);
		this.isEnabled = this.isEnabled.bind(this);
		this.handleOpenConfig = this.handleOpenConfig.bind(this);
		this.handleKeyUpSearchBox = this.handleKeyUpSearchBox.bind(this);
		this.handleSearchButton = this.handleSearchButton.bind(this);
		this.makeAventaCall = this.makeAventaCall.bind(this);
		this.handleShowSideSearchMenuList = this.handleShowSideSearchMenuList.bind(this);
		this.handleShowNotifResult = this.handleShowNotifResult.bind(this);
		this.handleShowAgentSideMenu = this.handleShowAgentSideMenu.bind(this);
		this.onStatusLoad = this.onStatusLoad.bind(this);
		this.changeView = this.changeView.bind(this);
		this.aventaToggleTimerId = null;
		this.state = {
			disableAventaCallButton: false,
			audioInputListS: null,
			showCollapsedSideSearchMenu: false,
			showCollapsedNotif: false,
			showCollapsedAgentMenu: false
		}
	}
	componentDidMount() {
		if(features["chat"]) {
			if (this.props.onHandleChatConfig) {
				this.props.onHandleChatConfig(false);
			}
		}
		if(!features["hide-agent-avatar-menu"]) {
			if (this.props.loadAgentStatusList) {
				this.props.loadAgentStatusList();
			}
		}
	}
	componentWillUnmount() {
		if (this.props.onUnload) {
			this.props.onUnload();
		}
	}
	handlePickUpNext(activate) {
		this.props.onTogglePickUpNext(activate);
	}
	isEnabled(serviceType) {
		let enabled = true;
		if (this.props.chatConfig.data && typeof this.props.chatConfig.data[serviceType] !== "undefined") {
			enabled = this.props.chatConfig.data[serviceType];
		}
		return enabled;
	}
	handleOpenConfig() {
		let chatConfigOpen = this.props.chatConfigOpen;
		this.props.onHandleOpenChatConfig(!chatConfigOpen);
	}
	makeAventaCall() {
		this.props.onChangeManualCallPopup(MP_BASIC_CALL);
	}
	isSearchPage = () => {
		let page = this.props.pageAddress;
		if (page !== `${process.env.PATH_PREFIX}v5/search`) {
			return false
		}
		return true;
	}
	handleKeyUpSearchBox(e) {
		if (this.props.disableActiveSearch == false ||
			(this.props.disableActiveSearch == true && e.key == "Enter")) {
			this.props.handleKeyUpSearchBox(e, this.props.disableActiveSearch);
		}
	}
	handleSearchButton(e) {
		if (this.props.disableActiveSearch == true) {
			this.props.handleSearchButton(e, this.props.searchText);
		}
	}
	handleShowSideSearchMenuList() {
		this.setState({ showCollapsedSideSearchMenu: !this.state.showCollapsedSideSearchMenu });
	}
	handleShowNotifResult() {
		this.setState({ showCollapsedNotif: !this.state.showCollapsedNotif });
	}
	handleShowAgentSideMenu() {
		this.setState({ showCollapsedAgentMenu: !this.state.showCollapsedAgentMenu });
	}
	onStatusLoad() {
		if (this.props.onStatusLoad) {
			this.props.onStatusLoad(this.props.aventaEnabled);
		}
	}
	changeView(page) {
		this.props.onChangeView(page);
	}
	render() {
		const {
			acceptCall
			, callEnabled
			, aventaEnabled
			, hideManual
			, enablePickupNext
			, forcePickupNext
			, collapseSideBar
			, forcedPickupNext
			, onChangeAcceptCall
			, onChangeManualPopup
			, onChangeManualCallPopup
			, mobileView
			, chatConfigOpen
			, isSearching
			, searchResults
			, searchText
			, showResultBox
			, handleKeyDownSearchBox
			, handleKeyUpSearchBox
			, handleChangeSearchBox
			, handleSearchButton
			, handleHeaderSearchDD
			, openErrand
			, openErrandFromSearch
			, ...p
		} = this.props
			, noCall = !callEnabled
			;
		let status = p.chatConfigOnlineStatus
			, chatConfigStatus = I('{ACTIVE_CHANNELS} of {ALL_CHANNELS} active')
				.replace('{ACTIVE_CHANNELS}', p.totalChatChannelActive)
				.replace('{ALL_CHANNELS}', p.totalChatChannel)
			;
		let hidePickupNext = (enablePickupNext == false && forcePickupNext == false)
		let pickupChecked = p.pickUpNext || forcePickupNext;
		let notificationMessages = p.notification.messages;
		let notificationCnt = 0,
			announcementCnt = 0;
		Object.values(notificationMessages).forEach(item => {
			if (item.read) {
				if (item.msg.text=="MsgAnnouncement") {
					announcementCnt++;
				} else {
					notificationCnt++;
				}
			}
		});
		// let firstFourNotifications = Object.values(notificationMessages).reverse().slice(0, 4);
		// firstFourNotifications.forEach(item => {
		// 	if (!item.read) {
		// 		notificationCnt++;
		// 	}
		// });
		let notifMsgLen = p.notification.uiData.noticeCount;
		return (
			// Todo: Best refactor this (in terms of jsx & classnames) for readability, consistancy and maintainability
			<SidebarSettingsList>
				<SidebarButtonsList>
					{
						!collapseSideBar &&
						!this.isSearchPage() &&
							<li id="side-search-menu" key={"side-search"} className={classNames("collapse-menu active search-menu", { show: false })}>
								<div className='pointerEventsNone'>
								<a title={I("Search")} href={`${process.env.PATH_PREFIX}v5/search`}>
									<i className="icon-search" /> <span className="search-side-title">{I("Search")}</span>
								</a>
								</div>
								<SideBarDropdownWrapperWithTooltip show={this.state.showCollapsedSideSearchMenu} autohide={false} onToggle={this.handleShowSideSearchMenuList}
									className={classNames("collapse-tooltip-menu search-side-menu", { expand: showResultBox })}
									target={"side-search-menu"}
								>
									<li data-tooltip data-qa-id="search_side_menu" className={"current"}>
										<HeaderSearch
											isSearching={isSearching}
											searchResults={searchResults}
											searchText={searchText}
											showResultBox={showResultBox}
											onKeyDown={handleKeyDownSearchBox}
											onKeyUp={this.handleKeyUpSearchBox}
											onChange={handleChangeSearchBox}
											onSearchButton={this.handleSearchButton}
											handleHeaderDD={handleHeaderSearchDD}
											openErrand={openErrandFromSearch}
											withHoverForInput={true}
											resultTextLimit={SEARCH_SIDEBAR_RESULT_TXT_LIMIT}
											key="side-search-menu-with-input"
										/>
									</li>
								</SideBarDropdownWrapperWithTooltip>
							</li>
					}
					{!collapseSideBar &&
						<li id="side-notif-menu-expand" className="collapse-menu active notif-menu" key={"side-notif-expand"}>
							{
								notifMsgLen > 0 ?
								<div className='pointerEventsNone'>
									<a data-notices-count={notifMsgLen} data-qa-id="header-notif" data-dropdown-target="notices-dropdown" className="with-notices">
										<i className="icon-bell"></i>
										<span className="notif-side-title">{I("Notifications")}</span>
									</a> 										
								</div>
									:
								<div className='pointerEventsNone'>
									<a data-qa-id="header-notif" data-dropdown-target="notices-dropdown" className="clear">
										<i className="icon-bell"></i>
										<span className="notif-side-title">{I("Notifications")}</span>
									</a>
								</div>
							}
							<SideBarDropdownWrapperWithTooltip
								show={this.state.showCollapsedNotif && notifMsgLen > 0}
								className={"collapse-tooltip-menu notif-side-menu-collapse agent-notif-side-menu"+(features["telavox"] ? " forTelavox" : "")}
								autohide={false}
								onToggle={this.handleShowNotifResult}
								collapsed={true}
								target={"side-notif-menu-expand"} >
								<li data-tooltip data-qa-id="notif_side_menu" className={"current"}>
									<AgentNotification
										title={I("Notifications")}
										messages={p.notification.messages}
										onToggleArrow={p.onToggleArrow}
										onSetNotificationTab={p.onSetNotificationTab}
										openAnnouncement={p.openAnnouncement}
										onShowAllNotification={p.onShowAllNotification}
										noticeCount={notifMsgLen}
										uiData={p.uiData}
										agentTimezoneOffset={p.agentTimezoneOffset}
										openLastErrandInThread={p.OpenLastErrandInThread}
										openCollaReplyPanel={p.openCollaReplyPanel}
										openErrand={openErrand}
										openMessage={p.openMessage}
										openStatistics={p.openStatistics}
										openAccounts={p.openAccounts}
										forSidebar={true}
									/>
								</li>
							</SideBarDropdownWrapperWithTooltip>
						</li>
					}
					{
						!features["hide-agent-avatar-menu"] &&
							<li  id="side-agent-menu-expand" key={"side-agent-menu-expand"} className={classNames("collapse-menu agent-menu active", { collapsed: collapseSideBar, dc: !p.uiData.wsConnected })}>
							<StyledUserStatusWithAvatar status={p.agentStatus.className} disconnected={!p.uiData.wsConnected} collapsed={collapseSideBar} className={classNames(
									{ "pointerEventsNone": !collapseSideBar })}>
								<ProfilePhoto photo={p.agent.photo} onLoad={this.onStatusLoad}
									width={"30"} isAgent={true}
									className="user-photo"/>
							</StyledUserStatusWithAvatar>
							<span  className={classNames("user-name", 
									{ "pointerEventsNone": !collapseSideBar })}  hidden={collapseSideBar}>{p.agent.name}</span>
							{!p.uiData.wsConnected
								? <span style={{ color: centionRed }} className={classNames("user-connect-status", 
								{ "pointerEventsNone": !collapseSideBar })} title={I('Connecting ...')}>
									<i className="fa fa-refresh fa-spin" />
								</span>
								: null
							}
							<SideBarDropdownWrapperWithTooltip show={this.state.showCollapsedAgentMenu} autohide={false} onToggle={this.handleShowAgentSideMenu}
								className={classNames("collapse-tooltip-menu agent-side-menu", { collapsed: collapseSideBar })}
								target={"side-agent-menu-expand"} >
								<li data-tooltip data-qa-id="agent_side_menu" className={"current"}>
									<AgentStatusChanger
										agentStatus={p.agentStatus}
										agentStatusById={p.agentStatusById}
										agentStatusByName={p.agentStatusByName}
										agentStatusList={p.agentStatusList}
										menu={p.menu}
										onToggleArrow={p.onToggleArrow}
										onUpdateStatus={p.onUpdateStatus}
										onLoad={this.onStatusLoad}
										uiData={p.uiData}
										embeddedWithMenu={true}
										key={"agent-status-changer-on-menu"}
									/>
									<AgentMenu
										menu={p.menu}
										onToggleArrow={p.onToggleArrow}
										agent={p.agent}
										uiData={p.uiData}
										newIMCount={p.newIMCount}
										onClickPreferences={p.onClickPreferences}
										onLinkChange={p.onLinkChange}
										withStatusChanger={true}
										menuOnly={true}
									/>
								</li>
							</SideBarDropdownWrapperWithTooltip>
						</li>
					}

					{noCall && this.props.sipEnabled == true &&
						<li className="collapse-menu call" hidden={false}>
							<SipStatus
								active={true}
								sipCallStatus={this.props.sipCallStatus}
								collapsed={collapseSideBar}
							/>
						</li>
					}
					<li className="collapse-menu call" hidden={noCall}>
						<CallIcon
							audioInputListS={this.state.audioInputListS}
							callPadPopupShown={p.callPadPopupShown}
							hidden={noCall}
							onClick={p.onClickCallIcon}
							onPopAlert={p.onPopAlert}
							onPopupAudioInputSelection={p.onPopupAudioInputSelection}
							onSelectAudioInput={p.onSelectAudioInput}
							selectedAudioInput={p.selectedAudioInput}
							status={p.callStatus}
							forSidebar={true}
							collapsed={collapseSideBar}
							disabled={this.props.sipMinimizeMode}
						/>
					</li>
					{!hideManual &&
						<PlusButtonWrapper>
							<PlusErrand
								onClick={onChangeManualPopup}
								disabled={this.props.isManualPopup}
								collapseSideBar={collapseSideBar}
							/>
						</PlusButtonWrapper>
					}
					{!hideManual &&
						<PlusButtonWrapper hidden={noCall}>
							<PlusCall
								onClick={onChangeManualCallPopup}
								disabled={this.props.isManualCallPopup}
								collapseSideBar={collapseSideBar}
							/>
						</PlusButtonWrapper>
					}
					{!hideManual && noCall && (aventaEnabled ||
						this.props.sipEnabled) &&
						<PlusButtonWrapper hidden={false} disabled={this.props.sipMinimizeMode}>
							<PlusCall
								onClick={this.makeAventaCall}
								disabled={this.props.sipMinimizeMode}
								collapseSideBar={collapseSideBar}
							/>
							<audio id="CentionManualCallAudio"
								hidden={true}
								loop={true}
								autoPlay
							/>
							<audio id="CentionTransferCallAudio"
								hidden={true}
								loop={true}
								autoPlay
							/>
						</PlusButtonWrapper>
					}
				</SidebarButtonsList>
				{!hidePickupNext &&
					<ToggleSwitch
						id={"pick-up_next-styler"}
						name={"pick-up_next"}
						label={I("Pick-up next")}
						icon={"icon-pick-up-next"}
						collapseSideBar={collapseSideBar}
						checked={pickupChecked}
						disabled={forcePickupNext}
						handleToggle={this.handlePickUpNext}
						hide={mobileView}
						className={"sidebar-toggle-wrapper"}
					/>
				}
				<CallToggleSwitch
					accept={acceptCall}
					disabled={acceptCall === AS_UNKNOWN}
					collapseSideBar={collapseSideBar}
					onClick={onChangeAcceptCall}
					hidden={noCall}
					className={"sidebar-toggle-wrapper"}
				/>
				{p.chatEnabled &&
					<ChatToggleSwitch
						id={"chat-styler"}
						name={"chat"}
						label={!collapseSideBar ? I("Channel") : ""}
						title={I("Channel")}
						collapseSideBar={collapseSideBar}
						status={status}
						config={true}
						chatConfigOpen={chatConfigOpen}
						statusText={chatConfigStatus}
						handleClick={this.handleOpenConfig}
						handleConfig={this.handleOpenConfig}
						className={"sidebar-toggle-wrapper chat-toggle-switch"}
					/>
				}
				<ChatSourceConfigCtnr collapseSideBar={collapseSideBar} />
			</SidebarSettingsList>
		);
	}
}
//NOTE(mujibur):  SideBarButtons imported as  SidebarSettings, why do we need such un-readable magic?
export default SideBarButtons;
