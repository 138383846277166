import React, {
	PureComponent
	, useEffect, useState, useCallback, Fragment
} from 'react';
import {
	A_FORM_DATA,
	OBJ_ID_NAME,
	M_FAQWIDGETDL,
	M_VOICEWIDGETDL,
	WIDGET_POSITION_OPTION,
	WIDGET_MODERN_POSITION_OPTION,
	FAQ_WIDGET_POSITION_OPTION,
	WIDGET_SKIN_OPTION,
	FAQ_WIDGET_SKIN_OPTION,
	WIDGET_SKIN_FRICTIONLESS,
	M_CHATWIDGETDL,
	CHAT_PROACTIVE_DEFAULTS,
	FAQ_WIDGET_SKIN_FRICTIONLESS
} from '../../common/v5/constants';
import { idNameSmall } from '../../common/constants';
import { centionTitleGrey } from '../../styles/_variables.js';
import { NoFlipMultiSelect } from '../../reactcomponents/Dropdown';
import {
	Label,
	TableIconicButton,
	FormInputWithLabelRow,
	TextInputRow,
	SelectInputRow,
	AvatarInputRow,
	HelperTooltip,
	withRow,
	CheckboxInputRow,
	ReadOnlyTextField,
	ClickableLableWithHelper
} from '../../reactcomponents/Form';
import { I, webRoot } from '../../common/v5/config';
import ColorPicker from '../../reactcomponents/ColorPicker';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../components/v5/ToggleSwitch';
import {
	TabContent
	, TabPane
	, Nav
	, Carousel
	, CarouselItem
	, CarouselControl
	, CarouselIndicators
	, CarouselCaption
} from 'reactstrap';
import { OneNavItem } from '../../reactcomponents/NavTabs';
import { Tab, TabNav } from '../../reactcomponents/Tabs';
import WidgetPreview from  '../../components/v5/WidgetPreview';
import { sanitizeHtml } from '../../common/v5/helpers';
import { createIntegrityAttr } from '../../common/v5/utils';
import {uuidv4 } from "../../common/helpers";

const WidgetCloudFrontURL = process.env.CLOUDFRONT_URL.replace(/\/$/, "");

//helpers
const helperEULA = I("Asks user to agree to the End User License Agreement before start chatting");
const helpertTranslation = I("JSON format of text to be translated and its translated part. Eg. \"Agent is typing a message...\": \"L'agente sta scrivendo un messaggio ...\" ");
const helperMessenger = I("The link for messenger, e.g \"https://m.me.com/your.name\"");
const helperWhatsApp = I("The link for whatsApp messaging, e.g \"https://api.whatsapp.com/send?phone=60123456789\"");
const helperTelegram = I("The link for telegram messaging, e.g \"https://t.me/joinchat/wBhNTY9-Y1cPHiIF\"");
let tabs = ['Preview'];

//basic configs
const ChatBasicConfig = ({
	input
	, onHandleTextInputChange
	, onHandleTextInputBlur
	, areaWarning
	, warning
	, currAreaList
	, onHandleSelectArea
	, onHandleToggleArea
	, onHandleDataChange
	, onSelectData
	, showArea
}) => {

	const cFlagSkin = cflag.IsActive("2023-11-23.CEN-1888-new-chat-widget-skin")
	const newSkin = {id: WIDGET_SKIN_FRICTIONLESS, name: I("Frictionless")}

	if (cFlagSkin && !WIDGET_SKIN_OPTION.some(skin => skin.id === newSkin.id)) {
		WIDGET_SKIN_OPTION.push(newSkin);
	}
	return (
		<section className="chat basic-config">
			<TextInputRow
				id={"adminChat-name"}
				name="name"
				className="admin-text-input"
				label={I("Name")}
				mandatory={true}
				value={input.name || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<div className="single-row">
				<FormInputWithLabelRow
					label={I('Area')}
					mandatory={true}
					warning={areaWarning}
				>
					<NoFlipMultiSelect
						id={"adminChat-area"}
						title={I("Select Area")}
						className="popup-multi-select"
						overrideTitle={true}
						groupSelect={true}
						nested={{key: 'Areas'}}
						data={currAreaList}
						idFields={OBJ_ID_NAME}
						selected={input.activeAreas}
						onSelect={onHandleSelectArea}
						onToggle={onHandleToggleArea}
						show={showArea}
						selectAll={true}
						selectNone={true}
					/>
				</FormInputWithLabelRow>
				<SelectInputRow
					hidden={false}
					id={"adminChat-skin"}
					name={"skin"}
					label={I('Skin')}
					className={"admin-select-review-option"}
					option={WIDGET_SKIN_OPTION}
					value={input.skin}
					onSelect={onSelectData}
				/>
			</div>
			<TextInputRow
				id={"adminChat-baseURL"}
				name="baseURL"
				className="admin-text-input"
				label={I("Base URL")}
				mandatory={true}
				value={input.baseURL || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			{
				//hides this now since we're not yet handled file versioning
				//the integrity attribute will invalid if the file changed on each release
				//so if this used, then customer must generate the new code each time the file changed
				/* <ToggleSwitch
					id={"integrityAttr"}
					name={"integrityAttr"}
					data-qa-id="adminChat-integrityAttr"
					label={I("Includes integrity attribute")}
					checked={input.integrityAttr}
					onClick={onHandleDataChange}
					className="admin-text-input"
					helperTxt={I("Includes integrity attributes on the chat widget installation code to ensure that the code is not modified by a third party")}
				/> */
			}
 			<TextInputRow
                id={"adminChat-widgetId"}
                name="widgetId"
                className="admin-text-input"
                label={I("Widget Id")}
                readOnly={true}
                mandatory={true}
                value={input.widgetId  || ""}
                onChange={onHandleTextInputChange}
                onBlur={onHandleTextInputBlur}
                warning={warning}
                overrideClassName="select-row"
            />
			<CheckboxInputRow
				label={I("Customized")}
				id={"adminChat-customize"}
				name={"customize"}
				data-qa-id="customize"
				checked={input.customize}
				onChange={onHandleDataChange}
			/>
		</section>
	)
}

const FaqBasicConfig = ({
	input
	, onHandleTextInputChange
	, onHandleTextInputBlur
	, areaWarning
	, warning
	, currAreaList
	, onHandleSelectArea
	, onHandleToggleArea
	, onHandleDataChange
	, showArea
	, libraryWarning
	, knowledgeBaseList
	, idFields
	, onHandleSelectLibrary
	, onHandleToggleLibrary
	, showLibrary
	, onSelectData
}) => {
	const cFlagSkin = cflag.IsActive("2024-06-14.CEN-1889-new-faq-widget-skin");
	const newSkin = {id: FAQ_WIDGET_SKIN_FRICTIONLESS, name: I("Frictionless")}

	if (cFlagSkin && !FAQ_WIDGET_SKIN_OPTION.some(skin => skin.id === newSkin.id)) {
		FAQ_WIDGET_SKIN_OPTION.push(newSkin);
	}
	return (
		<section className="faq basic-config">
			<TextInputRow
				id={"adminFaq-name"}
				name="name"
				className="admin-text-input"
				label={I("Name")}
				mandatory={true}
				value={input.name || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<div className="single-row">
				<TextInputRow
					id={"adminFaq-baseURLFAQ"}
					name="baseURLFAQ"
					className="admin-text-input"
					label={I("Base URL")}
					mandatory={true}
					value={input.baseURLFAQ || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					overrideClassName="select-row"
				/>
				<SelectInputRow
					hidden={false}
					id={"adminFaq-skin"}
					name={"skin"}
					label={I('Skin')}
					className={"admin-select-review-option"}
					option={FAQ_WIDGET_SKIN_OPTION}
					value={input.skin}
					onSelect={onSelectData}
				/>
			</div>
			{
			//hides this now since we're not yet handled file versioning
			//the integrity attribute will invalid if the file changed on each release
			//so if this used, then customer must generate the new code each time the file changed
			/* <div className="single-row">
					<ToggleSwitch
						id={"integrityAttr"}
						name={"integrityAttr"}
						data-qa-id="adminFaq-integrityAttr"
						label={I("Includes integrity attribute")}
						checked={input.integrityAttr}
						onClick={onHandleDataChange}
						className="admin-text-input"
						helperTxt={I("Includes integrity attributes on the chat widget installation code to ensure that the code is not modified by a third party")}
					/>
				</div> */
			}
			<div className="single-row">
				<FormInputWithLabelRow
					label={I('Library')}
					mandatory={true}
					warning={libraryWarning}
				>
					<NoFlipMultiSelect
						id={"adminFaq-library"}
						title={I("Select Library")}
						className="popup-multi-select"
						overrideTitle={true}
						groupSelect={true}
						data={knowledgeBaseList}
						idFields={idFields}
						selected={input.activeLibraries}
						onSelect={onHandleSelectLibrary}
						onToggle={onHandleToggleLibrary}
						show={showLibrary}
						selectAll={true}
						selectNone={true}
					/>
				</FormInputWithLabelRow>
				<FormInputWithLabelRow
					label={I('Area')}
					mandatory={true}
					warning={areaWarning}
				>
					<NoFlipMultiSelect
						id={"adminFaq-area"}
						title={I("Select Area")}
						className="popup-multi-select"
						overrideTitle={true}
						groupSelect={true}
						nested={{key: 'Areas'}}
						data={currAreaList}
						idFields={OBJ_ID_NAME}
						selected={input.activeAreas}
						onSelect={onHandleSelectArea}
						onToggle={onHandleToggleArea}
						show={showArea}
						selectAll={true}
						selectNone={true}
					/>
				</FormInputWithLabelRow>
			</div>
			<div className="single-row">
				<CheckboxInputRow
					label={I("Browse library")}
					id={"adminFaq-browseLibrary"}
					name={"browseLibrary"}
					data-qa-id="browseLibrary"
					checked={input.browseLibrary}
					onChange={onHandleDataChange}
				/>
				<CheckboxInputRow
					label={I("Featured first library")}
					id={"adminFaq-featuredFirstLibrary"}
					name={"featuredFirstLibrary"}
					data-qa-id="featuredFirstLibrary"
					checked={input.featuredFirstLibrary}
					onChange={onHandleDataChange}
					helperTxt={I("Feature first library on top when Browse Library is used. Only supported on modern skin.")}
					width={"30%"}
				/>
				<CheckboxInputRow
					label={I("Top list")}
					id={"adminFaq-topList"}
					name={"topList"}
					data-qa-id="topList"
					checked={input.topList}
					onChange={onHandleDataChange}
					width={"20%"}
				/>
			</div>
			<div className="single-row">
				<TextInputRow
						id={"adminFaq-topListLimit"}
						name="topListLimit"
						type={"number"}
						className="admin-text-input"
						label={I(`Top list limit`)}
						value={input.topListLimit || "0"}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
				/>
				<TextInputRow
						id={"adminFaq-topListMinVotes"}
						name="topListMinVotes"
						type={"number"}
						className="admin-text-input"
						label={I(`Top list minimum votes`)}
						value={input.topListMinVotes || "0"}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Minimum votes required for a FAQ to be included in the top list")}
				/>
			</div>
			<div className="single-row">
				<CheckboxInputRow
						label={I("FAQ with chat")}
						id={"adminFaq-faqWithChat"}
						name={"faqWithChat"}
						data-qa-id="faqWithChat"
						checked={input.faqWithChat}
						onChange={onHandleDataChange}
					/>
				<CheckboxInputRow
					label={I("Customized")}
					id={"adminFaq-customize"}
					name={"customize"}
					data-qa-id="customize"
					checked={input.customize}
					onChange={onHandleDataChange}
				/>
			</div>
		</section>
	)
}

const VoiceBasicConfig = ({
	input
	, onHandleTextInputChange
	, onHandleTextInputBlur
	, areaWarning
	, warning
	, currAreaList
	, onHandleSelectArea
	, onHandleToggleArea
	, onHandleDataChange
	, onSelectData
	, showArea
}) => {
	return (
		<section className="chat basic-config">
			<TextInputRow
				id={"adminChat-name"}
				name="name"
				className="admin-text-input"
				label={I("Name")}
				mandatory={true}
				value={input.name || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<div className="single-row">
				<FormInputWithLabelRow
					label={I('Area')}
					mandatory={true}
					warning={areaWarning}
				>
					<NoFlipMultiSelect
						id={"adminChat-area"}
						title={I("Select Area")}
						className="popup-multi-select"
						overrideTitle={true}
						groupSelect={true}
						nested={{key: 'Areas'}}
						data={currAreaList}
						idFields={OBJ_ID_NAME}
						selected={input.activeAreas}
						onSelect={onHandleSelectArea}
						onToggle={onHandleToggleArea}
						show={showArea}
						selectAll={true}
						selectNone={true}
					/>
				</FormInputWithLabelRow>
			</div>
			<TextInputRow
				id={"adminChat-baseURL"}
				name="baseURL"
				className="admin-text-input"
				label={I("Base URL")}
				mandatory={true}
				value={input.baseURL || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			{
				////hides this now since we're not yet handled file versioning
				//the integrity attribute will invalid if the file changed on each release
				//so if this used, then customer must generate the new code each time the file changed
				/* <ToggleSwitch
					id={"integrityAttr"}
					name={"integrityAttr"}
					data-qa-id="adminChat-integrityAttr"
					label={I("Includes integrity attribute")}
					checked={input.integrityAttr}
					onClick={onHandleDataChange}
					className="admin-text-input"
					helperTxt={I("Includes integrity attributes on the chat widget installation code to ensure that the code is not modified by a third party")}
				/> */
			}
			<CheckboxInputRow
				label={I("Customized")}
				id={"adminChat-customize"}
				name={"customize"}
				data-qa-id="customize"
				checked={input.customize}
				onChange={onHandleDataChange}
			/>
		</section>
	)
}

//custom configs for CHAT
const ChatCustomAppearanceConfig = ({input, onChangeColor, configState, onResetDefault}) => {

	let secondaryBgColorLabel = I("Chat section background color");
	let isModern = input.skin === "modern";
	if(isModern) {
		secondaryBgColorLabel = I("Secondary body color");
	}

	return 	(
		<div className="config-wrapper appearance">
			<ReadOnlyTextField
				id={"adminChat-header-colorPicker"}
				name="header-colorPicker"
				className="admin-text-input"
				label={I("Header color")}
			>
				<ColorPicker id="headerColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"headerColor")}}
					selected={configState.headerColor}

				/>
				<ReadOnlyTextField
					id={"headerColor"}
					name="headerColor"
					className="admin-text-input"
					value={input.headerColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-headerText-colorPicker"}
				name="headerText-colorPicker"
				className="admin-text-input"
				label={I("Header text color")}
			>
				<ColorPicker id="headerTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"headerTextColor")}}
					selected={configState.headerTextColor}
				/>
				<ReadOnlyTextField
					id={"headerTextColor"}
					name="headerTextColor"
					className="admin-text-input"
					value={input.headerTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-main-colorPicker"}
				name="main-colorPicker"
				className="admin-text-input"
				label={I("Main body color")}
			>
				<ColorPicker id="mainColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"mainColor")}}
					selected={configState.mainColor}
				/>
				<ReadOnlyTextField
					id={"mainColor"}
					name="mainColor"
					className="admin-text-input"
					value={input.mainColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-chatConversationBgColor-colorPicker"}
				name="chatConversationBgColor-colorPicker"
				className="admin-text-input"
				label={secondaryBgColorLabel}
			>
				<ColorPicker id="chatConversationBgColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"chatConversationBgColor")}}
					selected={configState.chatConversationBgColor}
				/>
				<ReadOnlyTextField
					id={"chatConversationBgColor"}
					name="chatConversationBgColor"
					className="admin-text-input"
					value={input.chatConversationBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-warningText-colorPicker"}
				name="headerBorder-colorPicker"
				className="admin-text-input"
				label={I("Warning text color")}
				helperTxt={I("Text that appears on input form validation")}
			>
				<ColorPicker id="warningTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"warningTextColor")}}
					selected={configState.warningTextColor}
				/>
				<ReadOnlyTextField
					id={"warningTextColor"}
					name="warningTextColor"
					className="admin-text-input"
					value={input.warningTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-askEULAText-colorPicker"}
				name="askEULAText-colorPicker"
				className="admin-text-input"
				label={I("Ask EULA text color")}
			>
				<ColorPicker id="askEULATextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"askEULATextColor")}}
					selected={configState.askEULATextColor}
				/>
				<ReadOnlyTextField
					id={"askEULATextColor"}
					name="askEULATextColor"
					className="admin-text-input"
					value={input.askEULATextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-buttonStart-colorPicker"}
				name="buttonStart-colorPicker"
				className="admin-text-input"
				label={I("Start button color")}
			>
				<ColorPicker id="buttonStartColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"buttonStartColor")}}
					selected={configState.buttonStartColor}
				/>
				<ReadOnlyTextField
					id={"buttonStartColor"}
					name="buttonStartColor"
					className="admin-text-input"
					value={input.buttonStartColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-buttonStartBorder-colorPicker"}
				name="buttonStartBorder-colorPicker"
				className="admin-text-input"
				label={I("Start button border color")}
			>
				<ColorPicker id="buttonStartBorderColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"buttonStartBorderColor")}}
					selected={configState.buttonStartBorderColor}
				/>
				<ReadOnlyTextField
					id={"buttonStartBorderColor"}
					name="buttonStartBorderColor"
					className="admin-text-input"
					value={input.buttonStartBorderColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-buttonStartText-colorPicker"}
				name="buttonStartText-colorPicker"
				className="admin-text-input"
				label={I("Start button text color")}
			>
				<ColorPicker id="buttonStartTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"buttonStartTextColor")}}
					selected={configState.buttonStartTextColor}
				/>
				<ReadOnlyTextField
					id={"buttonStartTextColor"}
					name="buttonStartTextColor"
					className="admin-text-input"
					value={input.buttonStartTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-buttonAway-colorPicker"}
				name="buttonAway-colorPicker"
				className="admin-text-input"
				label={I("Away button color")}
				helperTxt={I("button similiar to \"start button\" but has text and appears when no agent online")}
			>
				<ColorPicker id="buttonAwayColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"buttonAwayColor")}}
					selected={configState.buttonAwayColor}
				/>
				<ReadOnlyTextField
					id={"buttonAwayColor"}
					name="buttonAwayColor"
					className="admin-text-input"
					value={input.buttonAwayColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-buttonAwayText-colorPicker"}
				name="buttonAwayText-colorPicker"
				className="admin-text-input"
				label={I("Away button text color")}
			>
				<ColorPicker id="buttonAwayTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"buttonAwayTextColor")}}
					selected={configState.buttonAwayTextColor}
				/>
				<ReadOnlyTextField
					id={"buttonAwayTextColor"}
					name="buttonAwayTextColor"
					className="admin-text-input"
					value={input.buttonAwayTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-buttonAwayBorder-colorPicker"}
				name="buttonAwayBorder-colorPicker"
				className="admin-text-input"
				label={I("Away button border color")}
			>
				<ColorPicker id="buttonAwayBorderColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"buttonAwayBorderColor")}}
					selected={configState.buttonAwayBorderColor}
				/>
				<ReadOnlyTextField
					id={"buttonAwayBorderColor"}
					name="buttonAwayBorderColor"
					className="admin-text-input"
					value={input.buttonAwayBorderColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-messageAwayText-colorPicker"}
				name="messageAwayText-colorPicker"
				className="admin-text-input"
				label={I("Away message text color")}
			>
				<ColorPicker id="messageAwayTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"messageAwayTextColor")}}
					selected={configState.messageAwayTextColor}
				/>
				<ReadOnlyTextField
					id={"messageAwayTextColor"}
					name="messageAwayTextColor"
					className="admin-text-input"
					value={input.messageAwayTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-buttonRetryText-colorPicker"}
				name="buttonRetryText-colorPicker"
				className="admin-text-input"
				label={I("Retry button text color")}
			>
				<ColorPicker id="buttonRetryTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"buttonRetryTextColor")}}
					selected={configState.buttonRetryTextColor}
				/>
				<ReadOnlyTextField
					id={"buttonRetryTextColor"}
					name="buttonRetryTextColor"
					className="admin-text-input"
					value={input.buttonRetryTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-messageBackground-colorPicker"}
				name="messageBackground-colorPicker"
				className="admin-text-input"
				label={I("Chat bubble background color (client)")}
			>
				<ColorPicker id="messageBackgroundColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"messageBackgroundColor")}}
					selected={configState.messageBackgroundColor}
				/>
				<ReadOnlyTextField
					id={"messageBackgroundColor"}
					name="messageBackgroundColor"
					className="admin-text-input"
					value={input.messageBackgroundColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-messageBackgroundColorAgent-colorPicker"}
				name="messageBackgroundColorAgent-colorPicker"
				className="admin-text-input"
				label={I("Chat bubble background color (agent)")}
			>
				<ColorPicker id="messageBackgroundColorAgent"
					onChangeColor={(c)=>{onChangeColor(c,"messageBackgroundColorAgent")}}
					selected={configState.messageBackgroundColorAgent}
				/>
				<ReadOnlyTextField
					id={"messageBackgroundColorAgent"}
					name="messageBackgroundColorAgent"
					className="admin-text-input"
					value={input.messageBackgroundColorAgent}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-chatConversationInfoTextColor-colorPicker"}
				name="chatConversationInfoTextColor-colorPicker"
				className="admin-text-input"
				label={I("Chat bubble info's text color")}
			>
				<ColorPicker id="chatConversationInfoTextColor"
					onChangeColor={(c)=>{onChangeColor(c,"chatConversationInfoTextColor")}}
					selected={configState.chatConversationInfoTextColor}
				/>
				<ReadOnlyTextField
					id={"chatConversationInfoTextColor"}
					name="chatConversationInfoTextColor"
					className="admin-text-input"
					value={input.chatConversationInfoTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				hidden={isModern}
				id={"adminChat-buttonToggleMenu-colorPicker"}
				name="buttonToggleMenu-colorPicker"
				className="admin-text-input"
				label={I("Toggle Menu button color")}
			>
				<ColorPicker id="buttonToggleMenuColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"buttonToggleMenuColor")}}
					selected={configState.buttonToggleMenuColor}
				/>
				<ReadOnlyTextField
					id={"buttonToggleMenuColor"}
					name="buttonToggleMenuColor"
					className="admin-text-input"
					value={input.buttonToggleMenuColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-toggleMenuText-colorPicker"}
				name="toggleMenuText-colorPicker"
				className="admin-text-input"
				label={I("Toggle menu text color")}
			>
				<ColorPicker id="toggleMenuTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"toggleMenuTextColor")}}
					selected={configState.toggleMenuTextColor}
				/>
				<ReadOnlyTextField
					id={"toggleMenuTextColor"}
					name="toggleMenuTextColor"
					className="admin-text-input"
					value={input.toggleMenuTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-messageSendIcon-colorPicker"}
				name="messageSendIcon-colorPicker"
				className="admin-text-input"
				label={I("Send message icon color")}
			>
				<ColorPicker id="messageSendIconColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"messageSendIconColor")}}
					selected={configState.messageSendIconColor}
				/>
				<ReadOnlyTextField
					id={"messageSendIconColor"}
					name="messageSendIconColor"
					className="admin-text-input"
					value={input.messageSendIconColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-footerText-colorPicker"}
				name="footerText-colorPicker"
				className="admin-text-input"
				label={I("Footer text color")}
			>
				<ColorPicker id="footerTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"footerTextColor")}}
					selected={configState.footerTextColor}
				/>
				<ReadOnlyTextField
					id={"footerTextColor"}
					name="footerTextColor"
					className="admin-text-input"
					value={input.footerTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-feedbackBg-colorPicker"}
				name="feedbackBg-colorPicker"
				className="admin-text-input"
				label={I("Satisfaction Feedback background color")}
			>
				<ColorPicker id="feedbackBgColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"feedbackBgColor")}}
					selected={configState.feedbackBgColor}
				/>
				<ReadOnlyTextField
					id={"feedbackBgColor"}
					name="feedbackBgColor"
					className="admin-text-input"
					value={input.feedbackBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-feedbackText-colorPicker"}
				name="feedbackText-colorPicker"
				className="admin-text-input"
				label={I("Satisfaction Feedback text color")}
			>
				<ColorPicker id="feedbackTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"feedbackTextColor")}}
					selected={configState.feedbackTextColor}
				/>
				<ReadOnlyTextField
					id={"feedbackTextColor"}
					name="feedbackTextColor"
					className="admin-text-input"
					value={input.feedbackTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-tickUnsent-colorPicker"}
				name="tickUnsent-colorPicker"
				className="admin-text-input"
				label={I("Tick unsent color")}
			>
				<ColorPicker id="tickUnsentColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"tickUnsentColor")}}
					selected={configState.tickUnsentColor}
				/>
				<ReadOnlyTextField
					id={"tickUnsentColor"}
					name="tickUnsentColor"
					className="admin-text-input"
					value={input.tickUnsentColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-tickUnread-colorPicker"}
				name="tickUnread-colorPicker"
				className="admin-text-input"
				label={I("Tick unread color")}
			>
				<ColorPicker id="tickUnreadColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"tickUnreadColor")}}
					selected={configState.tickUnreadColor}
				/>
				<ReadOnlyTextField
					id={"tickUnreadColor"}
					name="tickUnreadColor"
					className="admin-text-input"
					value={input.tickUnreadColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-tickSent-colorPicker"}
				name="tickSent-colorPicker"
				className="admin-text-input"
				label={I("Tick sent color")}
			>
				<ColorPicker id="tickSentColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"tickSentColor")}}
					selected={configState.tickSentColor}
				/>
				<ReadOnlyTextField
					id={"tickSentColor"}
					name="tickSentColor"
					className="admin-text-input"
					value={input.tickSentColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-tickRead-colorPicker"}
				name="tickRead-colorPicker"
				className="admin-text-input"
				label={I("Tick read color")}
			>
				<ColorPicker id="tickReadColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"tickReadColor")}}
					selected={configState.tickReadColor}
				/>
				<ReadOnlyTextField
					id={"tickReadColor"}
					name="tickReadColor"
					className="admin-text-input"
					value={input.tickReadColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-chatConversationTextColor-colorPicker"}
				name="chatConversationTextColor-colorPicker"
				className="admin-text-input"
				label={I("Chat section text color")}
			>
				<ColorPicker id="chatConversationTextColor"
					onChangeColor={(c)=>{onChangeColor(c,"chatConversationTextColor")}}
					selected={configState.chatConversationTextColor}
				/>
				<ReadOnlyTextField
					id={"chatConversationTextColor"}
					name="chatConversationTextColor"
					className="admin-text-input"
					value={input.chatConversationTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-chatConversationTextareaBgColor-colorPicker"}
				name="chatConversationTextareaBgColor-colorPicker"
				className="admin-text-input"
				label={I("Chat section textarea color")}
			>
				<ColorPicker id="chatConversationTextareaBgColor"
					onChangeColor={(c)=>{onChangeColor(c,"chatConversationTextareaBgColor")}}
					selected={configState.chatConversationTextareaBgColor}
				/>
				<ReadOnlyTextField
					id={"chatConversationTextareaBgColor"}
					name="chatConversationTextareaBgColor"
					className="admin-text-input"
					value={input.chatConversationTextareaBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-chatConversationTextareaColor-colorPicker"}
				name="chatConversationTextareaColor-colorPicker"
				className="admin-text-input"
				label={I("Chat section textarea's text color")}
			>
				<ColorPicker id="chatConversationTextareaColor"
					onChangeColor={(c)=>{onChangeColor(c,"chatConversationTextareaColor")}}
					selected={configState.chatConversationTextareaColor}
				/>
				<ReadOnlyTextField
					id={"chatConversationTextareaColor"}
					name="chatConversationTextareaColor"
					className="admin-text-input"
					value={input.chatConversationTextareaColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-chatToggleMenuIconsColor-colorPicker"}
				name="chatToggleMenuIconsColor-colorPicker"
				className="admin-text-input"
				label={I("Chat toggle icon color")}
			>
				<ColorPicker id="chatToggleMenuIconsColor"
					onChangeColor={(c)=>{onChangeColor(c,"chatToggleMenuIconsColor")}}
					selected={configState.chatToggleMenuIconsColor}
				/>
				<ReadOnlyTextField
					id={"chatToggleMenuIconsColor"}
					name="chatToggleMenuIconsColor"
					className="admin-text-input"
					value={input.chatToggleMenuIconsColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-chatToggleMenuIconsTextColor-colorPicker"}
				name="chatToggleMenuIconsTextColor-colorPicker"
				className="admin-text-input"
				label={I("Chat toggle icon's text color")}
			>
				<ColorPicker id="chatToggleMenuIconsTextColor"
					onChangeColor={(c)=>{onChangeColor(c,"chatToggleMenuIconsTextColor")}}
					selected={configState.chatToggleMenuIconsTextColor}
				/>
				<ReadOnlyTextField
					id={"chatToggleMenuIconsTextColor"}
					name="chatToggleMenuIconsTextColor"
					className="admin-text-input"
					value={input.chatToggleMenuIconsTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-cookieWarningBgColor-colorPicker"}
				name="cookieWarningBgColor-colorPicker"
				className="admin-text-input"
				label={I("Cookie warning background color")}
			>
				<ColorPicker id="cookieWarningBgColor"
					onChangeColor={(c)=>{onChangeColor(c,"cookieWarningBgColor")}}
					selected={configState.cookieWarningBgColor}
				/>
				<ReadOnlyTextField
					id={"cookieWarningBgColor"}
					name="cookieWarningBgColor"
					className="admin-text-input"
					value={input.cookieWarningBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-cookieWarningTextColor-colorPicker"}
				name="cookieWarningTextColor-colorPicker"
				className="admin-text-input"
				label={I("Cookie warning text color")}
			>
				<ColorPicker id="cookieWarningTextColor"
					onChangeColor={(c)=>{onChangeColor(c,"cookieWarningTextColor")}}
					selected={configState.cookieWarningTextColor}
				/>
				<ReadOnlyTextField
					id={"cookieWarningTextColor"}
					name="cookieWarningTextColor"
					className="admin-text-input"
					value={input.cookieWarningTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-cookieWarningSymbolColor-colorPicker"}
				name="cookieWarningSymbolColor-colorPicker"
				className="admin-text-input"
				label={I("Cookie warning's toggle icon color")}
			>
				<ColorPicker id="cookieWarningSymbolColor"
					onChangeColor={(c)=>{onChangeColor(c,"cookieWarningSymbolColor")}}
					selected={configState.cookieWarningSymbolColor}
				/>
				<ReadOnlyTextField
					id={"cookieWarningSymbolColor"}
					name="cookieWarningSymbolColor"
					className="admin-text-input"
					value={input.cookieWarningSymbolColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-feedbackBoxBgColor-colorPicker"}
				name="feedbackBoxBgColor-colorPicker"
				className="admin-text-input"
				label={I("Feedback Box's background color")}
				helperTxt={I("modal popup box that appears after rating satisfaction feedback")}
			>
				<ColorPicker id="feedbackBoxBgColor"
					onChangeColor={(c)=>{onChangeColor(c,"feedbackBoxBgColor")}}
					selected={configState.feedbackBoxBgColor}
				/>
				<ReadOnlyTextField
					id={"feedbackBoxBgColor"}
					name="feedbackBoxBgColor"
					className="admin-text-input"
					value={input.feedbackBoxBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-feedbackBoxTitleColor-colorPicker"}
				name="feedbackBoxTitleColor-colorPicker"
				className="admin-text-input"
				label={I("Feedback Box's title text color")}
			>
				<ColorPicker id="feedbackBoxTitleColor"
					onChangeColor={(c)=>{onChangeColor(c,"feedbackBoxTitleColor")}}
					selected={configState.feedbackBoxTitleColor}
				/>
				<ReadOnlyTextField
					id={"feedbackBoxTitleColor"}
					name="feedbackBoxTitleColor"
					className="admin-text-input"
					value={input.feedbackBoxTitleColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-feedbackBoxTextareaBgColor-colorPicker"}
				name="feedbackBoxTextareaBgColor-colorPicker"
				className="admin-text-input"
				label={I("Feedback Box's textarea color")}
			>
				<ColorPicker id="feedbackBoxTextareaBgColor"
					onChangeColor={(c)=>{onChangeColor(c,"feedbackBoxTextareaBgColor")}}
					selected={configState.feedbackBoxTextareaBgColor}
				/>
				<ReadOnlyTextField
					id={"feedbackBoxTextareaBgColor"}
					name="feedbackBoxTextareaBgColor"
					className="admin-text-input"
					value={input.feedbackBoxTextareaBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-feedbackBoxTextareaTextColor-colorPicker"}
				name="feedbackBoxTextareaTextColor-colorPicker"
				className="admin-text-input"
				label={I("Feedback Box's textarea text color")}
			>
				<ColorPicker id="feedbackBoxTextareaTextColor"
					onChangeColor={(c)=>{onChangeColor(c,"feedbackBoxTextareaTextColor")}}
					selected={configState.feedbackBoxTextareaTextColor}
				/>
				<ReadOnlyTextField
					id={"feedbackBoxTextareaTextColor"}
					name="feedbackBoxTextareaTextColor"
					className="admin-text-input"
					value={input.feedbackBoxTextareaTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-feedbackBoxBtnBgColor-colorPicker"}
				name="feedbackBoxBtnBgColor-colorPicker"
				className="admin-text-input"
				label={I("Feedback Box button's background color")}
			>
				<ColorPicker id="feedbackBoxBtnBgColor"
					onChangeColor={(c)=>{onChangeColor(c,"feedbackBoxBtnBgColor")}}
					selected={configState.feedbackBoxBtnBgColor}
				/>
				<ReadOnlyTextField
					id={"feedbackBoxBtnBgColor"}
					name="feedbackBoxBtnBgColor"
					className="admin-text-input"
					value={input.feedbackBoxBtnBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-feedbackBoxBtnTextColor-colorPicker"}
				name="feedbackBoxBtnTextColor-colorPicker"
				className="admin-text-input"
				label={I("Feedback Box button's text color")}
			>
				<ColorPicker id="feedbackBoxBtnTextColor"
					onChangeColor={(c)=>{onChangeColor(c,"feedbackBoxBtnTextColor")}}
					selected={configState.feedbackBoxBtnTextColor}
				/>
				<ReadOnlyTextField
					id={"feedbackBoxBtnTextColor"}
					name="feedbackBoxBtnTextColor"
					className="admin-text-input"
					value={input.feedbackBoxBtnTextColor}
				/>
			</ReadOnlyTextField>
			<ClickableLableWithHelper
				className="reset-default"
				childrenClass="reset-button"
				label={I("Reset to default")}
				helperTxt={I("Reset all color configs to default value")}
				onClick={() => { onResetDefault() }}
			/>
		</div>
	)
}

const ChatCustomTextConfig = ({input, onHandleTextInputChange, onHandleTextInputBlur, warning }) => {
	return 	(
		<div className="config-wrapper text">
			<TextInputRow
					id={"adminChat-textTitle"}
					name="textTitle"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Chat Header title")}
					value={input.textTitle || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textAvailableNote"}
					name="textAvailableNote"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Agents available text")}
					value={input.textAvailableNote}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textButtonAway"}
					name="textButtonAway"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Away button")}
					value={input.textButtonAway || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textButtonRetry"}
					name="textButtonRetry"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Retry button")}
					value={input.textButtonRetry || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textInputName"}
					name="textInputName"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Name input")}
					value={input.textInputName || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textInputEmail"}
					name="textInputEmail"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Email input")}
					value={input.textInputEmail || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			{
			input.adminChatEnableCallbackRequest &&
				<TextInputRow
						id={"adminChat-textInputPhone"}
						name="textInputPhone"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Phone input")}
						value={input.textInputPhone || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
				/>
			}
			<TextInputRow
					id={"adminChat-textInputQuestion"}
					name="textInputQuestion"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Question input")}
					value={input.textInputQuestion || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textNoSelectArea"}
					name="textNoSelectArea"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Select area")}
					value={input.textNoSelectArea || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textStatusConnecting"}
					name="textStatusConnecting"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Connecting status")}
					value={input.textStatusConnecting || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textStatusQueue"}
					name="textStatusQueue"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Queue status")}
					value={input.textStatusQueue || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textStatusConnected"}
					name="textStatusConnected"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Connected status")}
					value={input.textStatusConnected || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textMessageQueueing"}
					name="textMessageQueueing"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Queueing message")}
					value={input.textMessageQueueing || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					textArea={true}
					rows={3}
			/>
			<TextInputRow
					id={"adminChat-textMessageCurrentQueue"}
					name="textMessageCurrentQueue"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Current Queue message")}
					value={input.textMessageCurrentQueue || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textMessageAgentBusy"}
					name="textMessageAgentBusy"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Agent Busy message")}
					value={input.textMessageAgentBusy || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textMessageAway-admin"}
					name="textMessageAway"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Away message")}
					value={input.textMessageAway || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					textArea={true}
					rows={3}
			/>
			<TextInputRow
					id={"adminChat-textMessageEmailInvalid"}
					name="textMessageEmailInvalid"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Invalid Email message")}
					value={input.textMessageEmailInvalid || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textMessageChatEnded"}
					name="textMessageChatEnded"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Chat Ended message")}
					value={input.textMessageChatEnded || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textMenuSendFile"}
					name="textMenuSendFile"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Send File menu")}
					value={input.textMenuSendFile || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textMenuNewChat"}
					name="textMenuNewChat"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for New Chat menu")}
					value={input.textMenuNewChat || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textMenuFinishChat"}
					name="textMenuFinishChat"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Finish Chat menu")}
					value={input.textMenuFinishChat || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textMenuPrintChat"}
					name="textMenuPrintChat"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Print Chat menu")}
					value={input.textMenuPrintChat || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textMenuSaveChat"}
					name="textMenuSaveChat"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Save Chat menu")}
					value={input.textMenuSaveChat || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textMenuVideoChat"}
					name="textMenuVideoChat"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Video Chat menu")}
					value={input.textMenuVideoChat || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textTickSent"}
					name="textTickSent"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Sent indicator")}
					value={input.textTickSent || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textTickRead"}
					name="textTickRead"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Read indicator")}
					value={input.textTickRead || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textEULA"}
					name="textEULA"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for EULA")}
					value={input.textEULA || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					textArea={true}
					rows={3}
			/>
			<TextInputRow
					id={"adminChat-textCookieWarning"}
					name="textCookieWarning"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Cookie warning")}
					value={input.textCookieWarning || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					textArea={true}
					rows={3}
			/>
			<TextInputRow
					id={"adminChat-textSatisfactionMessage"}
					name="textSatisfactionMessage"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Satisfaction message")}
					value={input.textSatisfactionMessage || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textSatisfactionReceived"}
					name="textSatisfactionReceived"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Satisfaction Received message")}
					value={input.textSatisfactionReceived || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textFeedbackButton"}
					name="textFeedbackButton"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Feedback button")}
					value={input.textFeedbackButton || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textCommentBoxTitle"}
					name="textCommentBoxTitle"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Comment Box title")}
					value={input.textCommentBoxTitle || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textFeedbackBtnSend"}
					name="textFeedbackBtnSend"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Feedback Send button")}
					value={input.textFeedbackBtnSend || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textFeedbackBtnCancel"}
					name="textFeedbackBtnCancel"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Feedback Cancel button")}
					value={input.textFeedbackBtnCancel || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textContactFormSentNotice"}
					name="textContactFormSentNotice"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for sent form")}
					value={input.textContactFormSentNotice || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					textArea={true}
					rows={3}
			/>
			{ input.adminChatEnableCallbackRequest &&
				<Fragment>
					<TextInputRow
						id={"adminChat-textMsgWhereToCall"}
						name="textMsgWhereToCall"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Where to call")}
						value={input.textMsgWhereToCall || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Message to show how the callback can be requested.Eg. 'What number should we call you on?'")}
					/>
					<TextInputRow
						id={"adminChat-textMsgCallNow"}
						name="textMsgCallNow"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Call Now button ")}
						value={input.textMsgCallNow || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Message to show that client request the call right away.Eg. 'Call me asap'")}
					/>
					<TextInputRow
						id={"adminChat-textMsgCallSchedule"}
						name="textMsgCallSchedule"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Schedule call button")}
						value={input.textMsgCallSchedule || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Label for the schedule call button.Eg. 'Schedule a call'")}
					/>
					<TextInputRow
						id={"adminChat-textMsgToCall"}
						name="textMsgToCall"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for When to call input")}
						value={input.textMsgToCall || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Label for the schedule call date and time input.Eg. 'When to call'")}
					/>
					<TextInputRow
						id={"adminChat-textBtnSend"}
						name="textBtnSend"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Send button")}
						value={input.textBtnSend || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Label for the send button input.Eg. 'Send request'")}
					/>
					<TextInputRow
						id={"adminChat-textBtnCancel"}
						name="textBtnCancel"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Cancel button")}
						value={input.textBtnCancel || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Label for the cancel button input.Eg. 'Cancel'")}
					/>
					<TextInputRow
						id={"adminChat-textDateError"}
						name="textDateError"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Text for Date error message")}
						value={input.textDateError || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Error message for callback date selection. Usually shown if the date selected is outside of working hours. Eg. 'Please select another date or time'")}
					/>
				</Fragment>
			}
		</div>
	)
}

const ChatCustomFeatureConfig = ({input, onHandleTextInputChange, onHandleTextInputBlur, onHandleChangeData, onToggleSwitch, warning, featureAllowVidCall }) => {
	return (
		<div className="config-wrapper chat-features">
			<TextInputRow
				id={"adminChat-logo"}
				name="logo"
				className="admin-text-input"
				label={I("Chat logo")}
				value={input.logo || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
				helperTxt={I("Logo is shown at the top left hand side of the widget. If it starts with '<' then it is assumed to be class icon (e.g: < i class='icon-example' />), otherwise it is treated as an image and will be put in <img src=\"{logo}\">. Eg. 'https://chat-logo.example.com'")}
			/>
			<TextInputRow
					id={"adminChat-translation"}
					name="translation"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Translation")}
					value={input.translation}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					textArea={true}
					rows={input.translation ? 60 : 5}
					helperTxt={helpertTranslation}
			/>
			<TextInputRow
					id={"adminChat-buttonAwayCustomClass"}
					name="buttonAwayCustomClass"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Away button custom class")}
					value={input.buttonAwayCustomClass || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("Custom class names for the away button")}
			/>
			<TextInputRow
					id={"adminChat-tickKerningOffset"}
					name="tickKerningOffset"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Kerning offset")}
					value={input.tickKerningOffset || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("Offset between the two ticks")}
			/>
			<ToggleSwitch
					id={"adminChatButtonAwayEnable"}
					name={"buttonAwayEnable"}
					data-qa-id="buttonAwayEnable"
					label={I("Away button")}
					checked={input.adminChatButtonAwayEnable}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Sets whether to show the away button")}
			/>
			{
				input.adminChatButtonAwayEnable &&
				<TextInputRow
					id={"adminChat-buttonAwayLink"}
					name="buttonAwayLink"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I(`Away button link`)}
					value={input.buttonAwayLink || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("The redirect link when the button is clicked")}
				/>
			}
			<ToggleSwitch
				id={"adminChatQuestionFieldMandatory"}
				name={"questionFieldMandatory"}
				data-qa-id="questionFieldMandatory"
				label={I("Set mandatory for question")}
				checked={input.adminChatQuestionFieldMandatory}
				onClick={onToggleSwitch}
				listWrap={false}
				className="admin-text-input"
				helperTxt={I("Sets whether the question field is mandatory before starting chat")}
			/>
			<ToggleSwitch
					id={"adminChatExtraFieldEnabled"}
					name={"extraFieldEnabled"}
					data-qa-id="extraFieldEnabled"
					label={I("Extra input field")}
					checked={input.adminChatExtraFieldEnabled}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Extra input field for chat start form")}
			/>
			{
				input.adminChatExtraFieldEnabled &&
				<TextInputRow
					id={"adminChat-extraFieldDisplayName"}
					name="extraFieldDisplayName"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Extra field input")}
					value={input.extraFieldDisplayName || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			}
			{
				input.adminChatExtraFieldEnabled &&
				<ToggleSwitch
					id={"adminChatExtraFieldMandatory"}
					name={"extraFieldMandatory"}
					data-qa-id="extraFieldMandatory"
					label={I("Set mandatory for extra field")}
					checked={input.adminChatExtraFieldMandatory}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Sets whether the extra field is mandatory")}
				/>
			}
			<ToggleSwitch
					id={"adminChatChatRatingsEnabled"}
					name={"chatRatingsEnabled"}
					data-qa-id="chatRatingsEnabled"
					label={I("Chat ratings")}
					checked={input.adminChatChatRatingsEnabled}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Enable customers to rate chat")}
			/>
			<ToggleSwitch
					id={"adminChatAskEULA"}
					name={"askEULA"}
					data-qa-id="askEULA"
					label={I("Ask EULA")}
					checked={input.adminChatAskEULA}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={helperEULA}
			/>
			<ToggleSwitch
					id={"adminChatHideChatWhenOffline"}
					name={"hideChatWhenOffline"}
					data-qa-id="hideChatWhenOffline"
					label={I("Hide chat when offline")}
					checked={input.adminChatHideChatWhenOffline}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("This for not showing the chat widget if the chat is not open or no agent is available")}
			/>
			<ToggleSwitch
					id={"adminChatShowMenuAsDefault"}
					name={"showMenuAsDefault"}
					data-qa-id="showMenuAsDefault"
					label={I("Show menu as default")}
					checked={input.adminChatShowMenuAsDefault}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Decide whether to show chat Menu as default before toggle it")}
			/>
			<ToggleSwitch
					id={"adminChatNoPreselectArea"}
					name={"avoidPreselectArea"}
					data-qa-id="avoidPreselectArea"
					label={I("No pre-selecting first area in the list")}
					checked={input.adminChatNoPreselectArea}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("This will avoid pre-selecting first area in the list and user need to select area before starting chat")}
			/>
			<ToggleSwitch
					id={"adminChatShowAllAreaList"}
					name={"showAllAreaList"}
					data-qa-id="showAllAreaList"
					label={I("Show all area list")}
					checked={input.adminChatShowAllAreaList}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("This will show all areas by default using radio buttons")}
			/>
			<ToggleSwitch
					id={"adminChatShowContactFormWhenAway"}
					name={"showContactFormWhenAway"}
					data-qa-id="showContactFormWhenAway"
					label={I("Show contact form when away")}
					checked={input.adminChatShowContactFormWhenAway}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Show contact form when chat is not available")}
			/>
			{ featureAllowVidCall &&
			<ToggleSwitch
					id={"adminChatEnableVideoChat"}
					name={"enableVideoChat"}
					data-qa-id="enableVideoChat"
					label={I("Video chat")}
					checked={input.adminChatEnableVideoChat}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Enable video chat support")}
			/>
			}
			{ features["sip-enabled"] &&
			<ToggleSwitch
					id={"adminChatEnableCallbackRequest"}
					name={"enableCallbackRequest"}
					data-qa-id="enableCallbackRequest"
					label={I("Enable callback request")}
					checked={input.adminChatEnableCallbackRequest}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Enable callback request")}
			/>
			}
			<ToggleSwitch
					id={"adminChatHideAgentAvatarList"}
					name={"hideAgentAvatarList"}
					data-qa-id="hideAgentAvatarList"
					label={I("Hide agent avatar list")}
					checked={input.adminChatHideAgentAvatarList}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Hide list of agent's avatars on the chat form")}
			/>
			<ToggleSwitch
					id={"adminChatFullScreenOnSmallScreen"}
					name={"chatFullScreenOnSmallScreen"}
					data-qa-id="chatFullScreenOnSmallScreen"
					label={I("Show widget in a full screen on smaller devices")}
					checked={input.adminChatFullScreenOnSmallScreen}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Show Chat widget in a full screen mode on small screens")}
			/>
			<ToggleSwitch
				id={"adminChatMaximize"}
				name={"enableChatMaximize"}
				data-qa-id="enableChatMaximize"
				label={I("Maximized Chat function")}
				checked={input.adminChatMaximize}
				onClick={onHandleChangeData}
				className="admin-text-input"
				helperTxt={I("This function is called when the chat is maximized")}
			/>
			<ToggleSwitch
				id={"adminChatMinimize"}
				name={"enableChatMiniimize"}
				data-qa-id="enableChatMinimize"
				label={I("Minimized Chat function")}
				checked={input.adminChatMinimize}
				onClick={onHandleChangeData}
				className="admin-text-input"
				helperTxt={I("This function is called when the chat is minimized")}
			/>
			<ToggleSwitch
				id={"adminWidgetLoad"}
				name={"enableWidgetLoad"}
				data-qa-id="enableWidgetLoad"
				label={I("Loaded Chat function")}
				checked={input.adminWidgetLoad}
				onClick={onHandleChangeData}
				className="admin-text-input"
				helperTxt={I("This function is called when the chat widget is loaded")}
			/>
			<TextInputRow
					id={"adminChat-chatZIndex"}
					name="chatZIndex"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Z-index")}
					value={input.chatZIndex || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("A value to represent the widget's relative appearance among other elements in the same page on your website. e.g: '999999'")}
			/>
			<SelectInputRow
				hidden={false}
				id={"adminChat-widgetPosition"}
				name={"widgetPosition"}
				label={I('Widget position')}
				className={"admin-select-review-option"}
				option={input.skin !== "default" ? WIDGET_MODERN_POSITION_OPTION : WIDGET_POSITION_OPTION}
				value={input.widgetPosition}
				onSelect={onHandleChangeData}
				helperTxt={I("The placement of the widget on the screen")}
			/>
			<TextInputRow
					id={"adminChat-bottomPosition"}
					name="bottomPosition"
					type={"number"}
					className="admin-text-input"
					label={I(`Widget bottom position`)}
					value={input.bottomPosition || "0"}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("The y-axis position of the widget from the bottom (in pixels)")}
			/>
			<TextInputRow
					id={"adminChat-leftPosition"}
					name="leftPosition"
					type={"number"}
					className="admin-text-input"
					label={I(`Widget left position`)}
					value={input.leftPosition || "0"}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("The x-axis position of the widget from the side of the page (in pixels)")}
			/>
			<TextInputRow
					id={"adminChat-textButtonStart"}
					name="textButtonStart"
					className="admin-text-input with-helper"
					overrideClassName="select-row"
					label={I("Text for Start chat button (optional)")}
					value={input.textButtonStart || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("Leave empty to use default icon")}
					// position="right"
			/>

			<TextInputRow	
				id={"adminChat-Messenger"}
				name="messengerChat"
				className="admin-text-input"
				label={I("Messenger (optional)")}
				value={input.messengerChat || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
				helperTxt={helperMessenger}
			/>
			<TextInputRow
				id={"adminChat-Whatsapp"}
				name="whatsAppChat"
				className="admin-text-input"
				label={I("WhatsApp (optional)")}
				value={input.whatsAppChat || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
				helperTxt={helperWhatsApp}
			/>
			<TextInputRow
				id={"adminChat-Telegram"}
				name="telegramChat"
				className="admin-text-input"
				label={I("Telegram (optional)")}
				value={input.telegramChat || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
				helperTxt={helperTelegram}
			/>
		</div>
	)
}

//custom configs for Proactive Chat
const ChatProactiveFeatureConfig = ({input, configState, proactiveAvatarPreview, onHandleTextInputChange, onHandleTextInputBlur, onHandleChangeData, onChangeColor, onHandleAvatarCrop, onHandleAvatarClose, onHandleRemoveAvatar, onToggleSwitch, warning }) => {
	const proactiveLayouts = [
		{id: 1, name: I("Portrait") },
		{id: 2, name: I("Landscape")}
	];

	useEffect(() => {
		if (input.adminChatProactiveEnable) {
			// setting up default value on current widget if it's enabled
			if (input.proactiveDelayTime === "0" || input.proactiveDelayTime === null) {
				onHandleChangeData(CHAT_PROACTIVE_DEFAULTS.delayTime, "proactiveDelayTime");
				onHandleChangeData(CHAT_PROACTIVE_DEFAULTS.repeatedContact, "proactiveRepeatedContact");
				onHandleChangeData(CHAT_PROACTIVE_DEFAULTS.timeout, "proactiveTimeout");
				onHandleChangeData(CHAT_PROACTIVE_DEFAULTS.timeAfterDecline, "proactiveTimeAfterDecline");
			}
		}
	}, [input.adminChatProactiveEnable]);

	return (
		<div className="config-wrapper chat-features proactive">
			<div className='admin-input-wrapper-half'>
				<ToggleSwitch
					id={"adminChatProactiveEnable"}
					name={"proactiveEnable"}
					data-qa-id="proactiveEnable"
					label={I("Proactive")}
					checked={input.adminChatProactiveEnable}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input chat-widget"
					helperTxt={I("Sets whether proactive chat offer should appear")}
					inlineLabel={false}
				/>
				<ToggleSwitch
					id={"adminChatProactiveImmediateStart"}
					name={"proactiveImmediateStart"}
					data-qa-id="proactiveImmediateStart"
					label={I("Proactive immediate start")}
					checked={input.adminChatProactiveImmediateStart}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input chat-widget"
					helperTxt={I("Sets whether proactive chat should start without forcing visitor to enter name/email/etc")}
					inlineLabel={false}
				/>
			</div>
			<div className='admin-input-wrapper-half'>
				<TextInputRow
					id={"adminChat-proactiveDelayTime"}
					name="proactiveDelayTime"
					type={"number"}
					className="admin-text-input with-helper"
					label={I(`Proactive delay time`)}
					value={input.proactiveDelayTime || CHAT_PROACTIVE_DEFAULTS.delayTime}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("How long proactive chat offer should appear after page load (seconds)")}
					inlineLabel={false}
				/>
				<TextInputRow
					id={"adminChat-proactiveRepeatedContact"}
					name="proactiveRepeatedContact"
					type={"number"}
					className="admin-text-input with-helper"
					label={I(`Proactive repeated contact`)}
					value={input.proactiveRepeatedContact || CHAT_PROACTIVE_DEFAULTS.repeatedContact}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("How many times proactive chat should be appear for visitor")}
					inlineLabel={false}
				/>
			</div>
			<div className='admin-input-wrapper-half'>
				<TextInputRow
					id={"adminChat-proactiveTimeout"}
					name="proactiveTimeout"
					type={"number"}
					className="admin-text-input with-helper"
					label={I(`Proactive timeout`)}
					value={input.proactiveTimeout || CHAT_PROACTIVE_DEFAULTS.timeout}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("How long until proactive chat offer disappears by itself (seconds)")}
						inlineLabel={false}
				/>
				<TextInputRow
					id={"adminChat-proactiveTimeAfterDecline"}
					name="proactiveTimeAfterDecline"
					type={"number"}
					className="admin-text-input with-helper"
					label={I(`Proactive time after decline`)}
					value={input.proactiveTimeAfterDecline || CHAT_PROACTIVE_DEFAULTS.timeAfterDecline}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("How long until proactive chat offer reappear by itself (seconds)")}
					inlineLabel={false}
				/>
			</div>
			<FormInputWithLabelRow
				label={I('Proactive chat pop up type')}
				warning={""}
				className={"admin-select-popup-type"}
				inlineLabel={false}
			>
				<SelectInputRow
					id={"adminChat-proactivePopupType"}
					name={"proactivePopupType"}
					className={"admin-select-cb-event"}
					option={proactiveLayouts}
					value={input.proactivePopupType}
					textNoItemSelected={I("Select")}
					onSelect={onHandleChangeData}
				/>
			</FormInputWithLabelRow>
			<div className='admin-input-wrapper-half'>
				<TextInputRow
					id={"adminChat-textTitleProactive"}
					name="textTitleProactive"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Title text")}
					value={input.textTitleProactive || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					inlineLabel={false}
					helperTxt={I("Proactive dialog header or title")}
				/>
				<TextInputRow
					id={"adminChat-textMessageProactive"}
					name="textMessageProactive"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Description text")}
					value={input.textMessageProactive || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					inlineLabel={false}
					helperTxt={I("Leave this empty if only the title should be shown (one line)")}
				/>
			</div>
			{
				//Yes/no label not used on the new proactive design,
				//so hide em for now from the gui
			}
			<div className='admin-input-wrapper-half' hidden>
				<TextInputRow
						id={"adminChat-textButtonProactiveStart"}
						name="textButtonProactiveStart"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Chat Now button")}
						value={input.textButtonProactiveStart || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						inlineLabel={false}
				/>
				<TextInputRow
						id={"adminChat-textButtonProactiveLater"}
						name="textButtonProactiveLater"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Maybe Later button")}
						value={input.textButtonProactiveLater || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						inlineLabel={false}
				/>
			</div>
			<div className='admin-input-wrapper-half'>
				<ReadOnlyTextField
					id={"adminChat-proactiveTitleTextColor-colorPicker"}
					name="proactiveTitleTextColor-colorPicker"
					className="admin-text-input"
					label={I("Title text color")}
				>
					<ColorPicker id="hproactiveTitleTextColorPicker"
						onChangeColor={(c)=>{onChangeColor(c,"proactiveTitleTextColor")}}
						selected={configState.proactiveTitleTextColor}

					/>
					<ReadOnlyTextField
						id={"proactiveTitleTextColor"}
						name="proactiveTitleTextColor"
						className="admin-text-input"
						value={input.proactiveTitleTextColor}
					/>
				</ReadOnlyTextField>
				<ReadOnlyTextField
					id={"adminChat-proactiveDescTextColor-colorPicker"}
					name="proactiveDescTextColor-colorPicker"
					className="admin-text-input"
					label={I("Description text color")}
				>
					<ColorPicker id="proactiveDescTextColorPicker"
						onChangeColor={(c)=>{onChangeColor(c,"proactiveDescTextColor")}}
						selected={configState.proactiveDescTextColor}
					/>
					<ReadOnlyTextField
						id={"proactiveDescTextColor"}
						name="proactiveDescTextColor"
						className="admin-text-input"
						value={input.proactiveDescTextColor}
					/>
				</ReadOnlyTextField>
			</div>
			<ReadOnlyTextField
				id={"adminChat-proactiveDialogBgColor-colorPicker"}
				name="proactiveDialogBgColor-colorPicker"
				className="admin-text-input"
				label={I("Background color")}
			>
				<ColorPicker id="proactiveDialogBgColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"proactiveDialogBgColor")}}
					selected={configState.proactiveDialogBgColor}
				/>
				<ReadOnlyTextField
					id={"proactiveDialogBgColor"}
					name="proactiveDialogBgColor"
					className="admin-text-input"
					value={input.proactiveDialogBgColor}
				/>
			</ReadOnlyTextField>
			<div className="admin-input-avatar">
				<AvatarInputRow
					label={I('Upload image')}
					width={200}
					height={100}
					onCrop={onHandleAvatarCrop}
					onClose={onHandleAvatarClose}
					mimeTypes="image/jpeg,image/png,image/gif"
					preview={input.proactiveImageURL}
					src={""}
					className={"upload-avatar"}
					handleRemoveAvatar={onHandleRemoveAvatar}
					disabled={false}
				/>
			</div>
		</div>
	)
}

//custom configs for FAQ
const FaqCustomAppearanceConfig = ({input, configState, onChangeColor, onResetDefault}) => {
	let isModern = input.skin === "modern";
	return (
		<div className="config-wrapper appearance">
			<ReadOnlyTextField
				id={"adminFaq-mainBg-colorPicker"}
				name="mainBg-colorPicker"
				className="admin-text-input"
				label={I("Main background color")}
			>
				<ColorPicker id="mainBgColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"mainBgColor")}}
					selected={configState.mainBgColor}
				/>
				<ReadOnlyTextField
					id={"mainBgColor"}
					name="mainBgColor"
					className="admin-text-input"
					value={input.mainBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-mainText-colorPicker"}
				name="mainText-colorPicker"
				className="admin-text-input"
				label={I("Main text color")}
			>
				<ColorPicker id="mainTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"mainTextColor")}}
					selected={configState.mainTextColor}
				/>
				<ReadOnlyTextField
					id={"mainTextColor"}
					name="mainTextColor"
					className="admin-text-input"
					value={input.mainTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-headerBg-colorPicker"}
				name="headerBg-colorPicker"
				className="admin-text-input"
				label={I("Header background color")}
			>
				<ColorPicker id="headerBgColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"headerBgColor")}}
					selected={configState.headerBgColor}
				/>
				<ReadOnlyTextField
					id={"headerBgColor"}
					name="headerBgColor"
					className="admin-text-input"
					value={input.headerBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-headerText-colorPicker"}
				name="headerText-colorPicker"
				className="admin-text-input"
				label={I("Header text color")}
			>
				<ColorPicker id="headerTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"headerTextColor")}}
					selected={configState.headerTextColor}
				/>
				<ReadOnlyTextField
					id={"headerTextColor"}
					name="headerTextColor"
					className="admin-text-input"
					value={input.headerTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-iconSearch-colorPicker"}
				name="iconSearch-colorPicker"
				className="admin-text-input"
				label={I("Search icon color")}
			>
				<ColorPicker id="iconSearchColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"iconSearchColor")}}
					selected={configState.iconSearchColor}
				/>
				<ReadOnlyTextField
					id={"iconSearchColor"}
					name="iconSearchColor"
					className="admin-text-input"
					value={input.iconSearchColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-searchBarTextColor-colorPicker"}
				name="searchBarTextColor-colorPicker"
				className="admin-text-input"
				label={I("Search bar text color")}
			>
				<ColorPicker id="searchBarTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"searchBarTextColor")}}
					selected={configState.searchBarTextColor}
				/>
				<ReadOnlyTextField
					id={"searchBarTextColor"}
					name="searchBarTextColor"
					className="admin-text-input"
					value={input.searchBarTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-searchBarBgColor-colorPicker"}
				name="searchBarBgColor-colorPicker"
				className="admin-text-input"
				label={I("Search bar background color")}
			>
				<ColorPicker id="searchBarBgColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"searchBarBgColor")}}
					selected={configState.searchBarBgColor}
				/>
				<ReadOnlyTextField
					id={"searchBarBgColor"}
					name="searchBarBgColor"
					className="admin-text-input"
					value={input.searchBarBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-tabIconTextColor-colorPicker"}
				name="tabIconTextColor-colorPicker"
				className="admin-text-input"
				label={I("Tab icon text color")}
				hidden={isModern}
			>
				<ColorPicker id="tabIconTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"tabIconTextColor")}}
					selected={configState.tabIconTextColor}
				/>
				<ReadOnlyTextField
					id={"tabIconTextColor"}
					name="tabIconTextColor"
					className="admin-text-input"
					value={input.tabIconTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-tabIconBgColor-colorPicker"}
				name="tabIconBgColor-colorPicker"
				className="admin-text-input"
				label={I("Tab icon background color")}
				hidden={isModern}
			>
				<ColorPicker id="tabIconBgColor"
					onChangeColor={(c)=>{onChangeColor(c,"tabIconBgColor")}}
					selected={configState.tabIconBgColor}
				/>
				<ReadOnlyTextField
					id={"tabIconBgColor"}
					name="tabIconBgColor"
					className="admin-text-input"
					value={input.tabIconBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				hidden={isModern}
				id={"adminFaq-questionListBgColor-colorPicker"}
				name="questionListBgColor-colorPicker"
				className="admin-text-input"
				label={I("Question list background color")}
			>
				<ColorPicker id="questionListBgColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"questionListBgColor")}}
					selected={configState.questionListBgColor}
				/>
				<ReadOnlyTextField
					id={"questionListBgColor"}
					name="questionListBgColor"
					className="admin-text-input"
					value={input.questionListBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-questionListTextColor-colorPicker"}
				name="questionListTextColor-colorPicker"
				className="admin-text-input"
				label={I("Question list text color")}
			>
				<ColorPicker id="questionListTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"questionListTextColor")}}
					selected={configState.questionListTextColor}
				/>
				<ReadOnlyTextField
					id={"questionListTextColor"}
					name="questionListTextColor"
					className="admin-text-input"
					value={input.questionListTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-libraryListBgColor-colorPicker"}
				name="libraryListBgColor-colorPicker"
				className="admin-text-input"
				label={I("Library List background color")}
			>
				<ColorPicker id="libraryListBgColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"libraryListBgColor")}}
					selected={configState.libraryListBgColor}
				/>
				<ReadOnlyTextField
					id={"libraryListBgColor"}
					name="libraryListBgColor"
					className="admin-text-input"
					value={input.libraryListBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-libraryListTextColor-colorPicker"}
				name="libraryListTextColor-colorPicker"
				className="admin-text-input"
				label={I("Library list text color")}
			>
				<ColorPicker id="libraryListTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"libraryListTextColor")}}
					selected={configState.libraryListTextColor}
				/>
				<ReadOnlyTextField
					id={"libraryListTextColor"}
					name="libraryListTextColor"
					className="admin-text-input"
					value={input.libraryListTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-btnFeedbackBg-colorPicker"}
				name="btnFeedbackBg-colorPicker"
				className="admin-text-input"
				label={I("Feedback button background color")}
			>
				<ColorPicker id="btnFeedbackBgColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"btnFeedbackBgColor")}}
					selected={configState.btnFeedbackBgColor}
				/>
				<ReadOnlyTextField
					id={"btnFeedbackBgColor"}
					name="btnFeedbackBgColor"
					className="admin-text-input"
					value={input.btnFeedbackBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-btnFeedbackText-colorPicker"}
				name="btnFeedbackText-colorPicker"
				className="admin-text-input"
				label={I("Feedback button text color")}
			>
				<ColorPicker id="btnFeedbackTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"btnFeedbackTextColor")}}
					selected={configState.btnFeedbackTextColor}
				/>
				<ReadOnlyTextField
					id={"btnFeedbackTextColor"}
					name="btnFeedbackTextColor"
					className="admin-text-input"
					value={input.btnFeedbackTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-btnLaunchChatBg-colorPicker"}
				name="btnLaunchChatBg-colorPicker"
				className="admin-text-input"
				label={I("Launch chat button background color")}
			>
				<ColorPicker id="btnLaunchChatBgColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"btnLaunchChatBgColor")}}
					selected={configState.btnLaunchChatBgColor}
				/>
				<ReadOnlyTextField
					id={"btnLaunchChatBgColor"}
					name="btnLaunchChatBgColor"
					className="admin-text-input"
					value={input.btnLaunchChatBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-iconContact-colorPicker"}
				name="iconContact-colorPicker"
				className="admin-text-input"
				label={I("Contact Page icon color")}
			>
				<ColorPicker id="iconContactColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"iconContactColor")}}
					selected={configState.iconContactColor}
				/>
				<ReadOnlyTextField
					id={"iconContactColor"}
					name="iconContactColor"
					className="admin-text-input"
					value={input.iconContactColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-btnLaunchChatText-colorPicker"}
				name="btnLaunchChatText-colorPicker"
				className="admin-text-input"
				label={I("Launch chat button text color")}
			>
				<ColorPicker id="btnLaunchChatTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"btnLaunchChatTextColor")}}
					selected={configState.btnLaunchChatTextColor}
				/>
				<ReadOnlyTextField
					id={"btnLaunchChatTextColor"}
					name="btnLaunchChatTextColor"
					className="admin-text-input"
					value={input.btnLaunchChatTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				hidden={isModern}
				id={"adminFaq-faqChatFooterBgColor-colorPicker"}
				name="faqChatFooterBgColor-colorPicker"
				className="admin-text-input"
				label={I("Chat footer background color")}
			>
				<ColorPicker id="faqChatFooterBgColor"
					onChangeColor={(c)=>{onChangeColor(c,"faqChatFooterBgColor")}}
					selected={configState.faqChatFooterBgColor}
				/>
				<ReadOnlyTextField
					id={"faqChatFooterBgColor"}
					name="faqChatFooterBgColor"
					className="admin-text-input"
					value={input.faqChatFooterBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-triggerPanelBg-colorPicker"}
				name="triggerPanelBg-colorPicker"
				className="admin-text-input"
				label={I("Trigger panel background color")}
			>
				<ColorPicker id="triggerPanelBgColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"triggerPanelBgColor")}}
					selected={configState.triggerPanelBgColor}
				/>
				<ReadOnlyTextField
					id={"triggerPanelBgColor"}
					name="triggerPanelBgColor"
					className="admin-text-input"
					value={input.triggerPanelBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminFaq-triggerPanelText-colorPicker"}
				name="triggerPanelText-colorPicker"
				className="admin-text-input"
				label={I("Trigger panel text color")}
			>
				<ColorPicker id="triggerPanelTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"triggerPanelTextColor")}}
					selected={configState.triggerPanelTextColor}
				/>
				<ReadOnlyTextField
					id={"triggerPanelTextColor"}
					name="triggerPanelTextColor"
					className="admin-text-input"
					value={input.triggerPanelTextColor}
				/>
			</ReadOnlyTextField>
			<ClickableLableWithHelper
				className="reset-default"
				childrenClass="reset-button"
				label={I("Reset to default")}
				helperTxt={I("Reset all color configs to default value")}
				onClick={() => { onResetDefault() }}
			/>
		</div>
	)
}

const FaqCustomTextConfig = ({input, onHandleTextInputChange, onHandleTextInputBlur, warning}) => {
	return (
		<div className="config-wrapper text">
			<TextInputRow
				id={"adminFaq-textHeaderTitle"}
				name="textHeaderTitle"
				className="admin-text-input"
				label={I("Label for Widget Header")}
				value={input.textHeaderTitle || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textInputSearch"}
				name="textInputSearch"
				className="admin-text-input"
				label={I("Label for Search input")}
				value={input.textInputSearch || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textSearchResult"}
				name="textSearchResult"
				className="admin-text-input"
				label={I("Label for Search header")}
				value={input.textSearchResult || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textFeedbackQuestion"}
				name="textFeedbackQuestion"
				className="admin-text-input"
				label={I("Label for Feedback question")}
				value={input.textFeedbackQuestion || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textBtnUpvote"}
				name="textBtnUpvote"
				className="admin-text-input"
				label={I("Label for Upvote button")}
				value={input.textBtnUpvote || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textBtnDownvote"}
				name="textBtnDownvote"
				className="admin-text-input"
				label={I("Label for Downvote button")}
				value={input.textBtnDownvote || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textFeedbackMsg"}
				name="textFeedbackMsg"
				className="admin-text-input"
				label={I("Label for Feedback message")}
				value={input.textFeedbackMsg || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textCategoryHeading"}
				name="textCategoryHeading"
				className="admin-text-input"
				label={I("Label for Categories header")}
				value={input.textCategoryHeading || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactHeading"}
				name="textContactHeading"
				className="admin-text-input"
				label={I("Label for Contact header")}
				value={input.textContactHeading || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textEULAFAQ"}
				name="textEULAFAQ"
				className="admin-text-input"
				label={I("Label for EULA")}
				value={input.textEULAFAQ || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactNumTitle"}
				name="textContactNumTitle"
				className="admin-text-input"
				label={I("Label for Contact title")}
				value={input.textContactNumTitle || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactNumLine1"}
				name="textContactNumLine1"
				className="admin-text-input"
				label={I("Label for Contact No 1 input")}
				value={input.textContactNumLine1 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactHoursTitle"}
				name="textContactHoursTitle"
				className="admin-text-input"
				label={I("Label for Contact hours")}
				value={input.textContactHoursTitle || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactHoursLine1"}
				name="textContactHoursLine1"
				className="admin-text-input"
				label={I("Label for Contact Hours 1 input")}
				value={input.textContactHoursLine1 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactHoursLine2"}
				name="textContactHoursLine2"
				className="admin-text-input"
				label={I("Label for Contact Hours 2 input")}
				value={input.textContactHoursLine2 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactChatTitle"}
				name="textContactChatTitle"
				className="admin-text-input"
				label={I("Label for Chat title")}
				value={input.textContactChatTitle || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textFAQChatLauncher"}
				name="textFAQChatLauncher"
				className="admin-text-input"
				label={I("Label for Chat launcher")}
				value={input.textFAQChatLauncher || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactChatLine1"}
				name="textContactChatLine1"
				className="admin-text-input"
				label={I("Label for Chat 1 input")}
				value={input.textContactChatLine1 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactChatLine2"}
				name="textContactChatLine2"
				className="admin-text-input"
				label={I("Label for Chat 2 input")}
				value={input.textContactChatLine2 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactEmailTitle"}
				name="textContactEmailTitle"
				className="admin-text-input"
				label={I("Label for Email title")}
				value={input.textContactEmailTitle || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactEmailLine1"}
				name="textContactEmailLine1"
				className="admin-text-input"
				label={I("Label for Email 1 input")}
				value={input.textContactEmailLine1 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactEmailLine2"}
				name="textContactEmailLine2"
				className="admin-text-input"
				label={I("Label for Email 2 input")}
				value={input.textContactEmailLine2 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textContactEmailLine3"}
				name="textContactEmailLine3"
				className="admin-text-input"
				label={I("Label for Email 3 input")}
				value={input.textContactEmailLine3 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textListNotFoundDesc1"}
				name="textListNotFoundDesc1"
				className="admin-text-input"
				label={I("Label for Top List 1 message")}
				value={input.textListNotFoundDesc1 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textListNotFoundDesc2"}
				name="textListNotFoundDesc2"
				className="admin-text-input"
				label={I("Label for Top List 2 message")}
				value={input.textListNotFoundDesc2 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textFAQButton1"}
				name="textFAQButton1"
				className="admin-text-input"
				label={I("Label for FAQ 1 link")}
				value={input.textFAQButton1 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textFAQButton2"}
				name="textFAQButton2"
				className="admin-text-input"
				label={I("Label for FAQ 2 link")}
				value={input.textFAQButton2 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			<TextInputRow
				id={"adminFaq-textFAQButton3"}
				name="textFAQButton3"
				className="admin-text-input"
				label={I("Label for FAQ 3 link")}
				value={input.textFAQButton3 || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
			/>
			{ features["sip-enabled"] && input.adminFaqEnableCallbackRequest &&
				<Fragment>
					<TextInputRow
						id={"adminFaq-textCallbackTitle"}
						name="textCallbackTitle"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Request Callback title")}
						value={input.textCallbackTitle || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Label for the callback title.Eg. 'Request a call back'")}
					/>
					<TextInputRow
						id={"adminFaq-textCallbackWorkingHours"}
						name="textCallbackWorkingHours"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Text for working hours ")}
						value={input.textCallbackWorkingHours || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Text to show working hours.Eg. 'Monday - Friday: 9:00am - 6:00pm'")}
					/>
					<TextInputRow
						id={"adminChat-textCallbackInputName"}
						name="textCallbackInputName"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Name input")}
						value={input.textCallbackInputName || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Label for callback form name input.Eg. 'Name'")}
					/>
					<TextInputRow
						id={"adminChat-textCallbackInputEmail"}
						name="textCallbackInputEmail"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Email input")}
						value={input.textCallbackInputEmail || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Label for callback form email input.Eg. 'Email'")}
					/>
					<TextInputRow
						id={"adminChat-textCallbackInputPhone"}
						name="textCallbackInputPhone"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Phone input")}
						value={input.textCallbackInputPhone || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Label for callback form phone input.Eg. 'Phone'")}
					/>
					<TextInputRow
						id={"adminChat-textCallbackInputQuestion"}
						name="textCallbackInputQuestion"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Question input")}
						value={input.textCallbackInputQuestion || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Label for callback form question input.Eg. 'Message'")}
					/>
					<TextInputRow
						id={"adminChat-textNameInvalid"}
						name="textNameInvalid"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Message for invalid name format")}
						value={input.textNameInvalid || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Message for callback form invalid name format.Eg. 'You must enter a valid name'")}
					/>
					<TextInputRow
						id={"adminChat-textMessageEmailInvalid"}
						name="textMessageEmailInvalid"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Message for invalid email format")}
						value={input.textMessageEmailInvalid || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Message for callback form invalid email format.Eg. 'You must enter a valid email address'")}
					/>
					<TextInputRow
						id={"adminFaq-textMsgToCall"}
						name="textMsgToCall"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for When to call input")}
						value={input.textMsgToCall || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Label for the schedule call date and time input.Eg. 'When to call'")}
					/>
					<TextInputRow
						id={"adminFaq-textBtnSend"}
						name="textBtnSend"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Send button")}
						value={input.textBtnSend || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Label for the send button input.Eg. 'Send request'")}
					/>
					<TextInputRow
						id={"adminFaq-textBtnCancel"}
						name="textBtnCancel"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for Cancel button")}
						value={input.textBtnCancel || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Label for the cancel button input.Eg. 'Cancel'")}
					/>
					<TextInputRow
						id={"adminFaq-textDateError"}
						name="textDateError"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Text for Date error message")}
						value={input.textDateError || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Error message for callback date selection. Usually shown if the date selected is outside of working hours. Eg. 'Please select another date or time'")}
					/>
					<TextInputRow
						id={"adminFaq-textMsgSendSuccess"}
						name="textMsgSendSuccess"
						className="admin-text-input"
						overrideClassName="select-row"
						label={I("Label for sent form")}
						value={input.textMsgSendSuccess || ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Text showing after the user submitted the form. Eg. 'You have requested a callback through phone number at {SCHEDULE_TIME}, we will reach you at the appointed time.'")}
					/>
				</Fragment>
			}
		</div>
	)
}

const FaqCustomFeatureConfig = ({input, onHandleTextInputChange, onHandleTextInputBlur, onHandleChangeData, onToggleSwitch, warning, featureAllowVidCall, channels, allChannels }) => {
	return (
		<div className="config-wrapper chat-features">
			<ToggleSwitch
					id={"adminFaqHasChat"}
					name={"hasChat"}
					data-qa-id="hasChat"
					label={I("Has chat")}
					checked={input.adminFaqHasChat}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Include chat section on FAQ widget")}
			/>
			<ToggleSwitch
					id={"adminFaqShowContactPage"}
					name={"showContactPage"}
					data-qa-id="showContactPage"
					label={I("Show contact page")}
					checked={input.adminFaqShowContactPage}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Show contact page when click chat button.")}
			/>
			<ToggleSwitch
					id={"adminFaqShowMenuAsDefaultFAQ"}
					name={"showMenuAsDefaultFAQ"}
					data-qa-id="showMenuAsDefaultFAQ"
					label={I("Show menus as default")}
					checked={input.adminFaqShowMenuAsDefaultFAQ}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Shows the iconic chat menus by default")}
			/>
			<ToggleSwitch
					id={"adminFaqAskEULAFAQ"}
					name={"askEULAFAQ"}
					data-qa-id="askEULAFAQ"
					label={I("Ask EULA")}
					checked={input.adminFaqAskEULAFAQ}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={helperEULA}
			/>
			<ToggleSwitch
					id={"adminFaqButtonAwayEnableFAQ"}
					name={"buttonAwayEnableFAQ"}
					data-qa-id="buttonAwayEnableFAQ"
					label={I("Away button")}
					checked={input.adminFaqButtonAwayEnableFAQ}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Whether to show the away button")}
			/>
			<ToggleSwitch
					id={"adminFaqChatRatingsEnabledFAQ"}
					name={"chatRatingsEnabledFAQ"}
					data-qa-id="chatRatingsEnabledFAQ"
					label={I("Chat ratings")}
					checked={input.adminFaqChatRatingsEnabledFAQ}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Enable customers to rate chat")}
					/>
			{ featureAllowVidCall &&
			<ToggleSwitch
					id={"adminFaqEnableVideoChatFAQ"}
					name={"enableVideoChatFAQ"}
					data-qa-id="enableVideoChatFAQ"
					label={I("Video chat")}
					checked={input.adminFaqEnableVideoChatFAQ}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Enable video chat support")}
			/>
			}
			{ features["sip-enabled"] &&
			<ToggleSwitch
					id={"adminFaqEnableCallbackRequest"}
					name={"enableFaqCallbackRequest"}
					data-qa-id="enableFaqCallbackRequest"
					label={I("Enable callback request")}
					checked={input.adminFaqEnableCallbackRequest}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Enable callback request")}
			/>
			}
			<ToggleSwitch
					id={"adminFaqHideAgentAvatarList"}
					name={"hideAgentAvatarList"}
					data-qa-id="hideFaqAgentAvatarList"
					label={I("Hide agent avatar list")}
					checked={input.adminFaqHideAgentAvatarList}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Hide list of agent's avatars on the chat form")}
			/>
			<ToggleSwitch
					id={"adminFaqFullScreenOnSmallScreen"}
					name={"faqFullScreenOnSmallScreen"}
					data-qa-id="faqFullScreenOnSmallScreen"
					label={I("Show widget in a full screen on smaller devices")}
					checked={input.adminFaqFullScreenOnSmallScreen}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Show FAQ widget in a full screen mode on small screens")}
			/>
			<TextInputRow
					id={"adminFaq-ZIndex"}
					name="faqZIndex"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Z-index")}
					value={input.faqZIndex || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("A value to represent the widget's relative appearance among other elements in the same page on your website. e.g: '999999'")}
			/>
			<SelectInputRow
				hidden={false}
				id={"adminFaq-position"}
				name={"position"}
				label={I('Widget position')}
				className={"admin-select-review-option"}
				option={FAQ_WIDGET_POSITION_OPTION}
				value={input.position}
				onSelect={onHandleChangeData}
				helperTxt={I("The placement of the widget on the screen")}
			/>
			<TextInputRow
					id={"adminFaq-translationFAQ"}
					name="translationFAQ"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Translation")}
					value={input.translationFAQ}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					textArea={true}
					helperTxt={helpertTranslation}
			/>
			<TextInputRow
				id={"adminFaq-textButtonStartFAQ"}
				name="textButtonStartFAQ"
				className="admin-text-input with-helper"
				label={I("Text for Start FAQ chat button (optional)")}
				value={input.textButtonStartFAQ || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
				helperTxt={I("Leave empty to use default icon")}
			/>

			<TextInputRow	
				id={"adminFaq-Messenger"}
				name="messengerFAQ"
				className="admin-text-input"
				label={I("Messenger (optional)")}
				value={input.messengerFAQ || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
				helperTxt={helperMessenger}
			/>
			<TextInputRow
				id={"adminFaq-Whatsapp"}
				name="whatsAppFAQ"
				className="admin-text-input"
				label={I("WhatsApp (optional)")}
				value={input.whatsAppFAQ || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
				helperTxt={helperWhatsApp}
			/>
			<TextInputRow
				id={"adminFaq-Telegram"}
				name="telegramFAQ"
				className="admin-text-input"
				label={I("Telegram (optional)")}
				value={input.telegramFAQ || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
				helperTxt={helperTelegram}
			/>
		</div>
	)
}

//custom configs for Callback widget
const VoiceCustomAppearanceConfig = ({input, onChangeColor, configState, onResetDefault}) => {

	let secondaryBgColorLabel = I("Widget section background color");
	secondaryBgColorLabel = I("Secondary body color");

	return 	(
		<div className="config-wrapper appearance">
			<ReadOnlyTextField
				id={"adminChat-header-colorPicker"}
				name="header-colorPicker"
				className="admin-text-input"
				label={I("Header color")}
			>
				<ColorPicker id="headerColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"headerColor")}}
					selected={configState.headerColor}

				/>
				<ReadOnlyTextField
					id={"headerColor"}
					name="headerColor"
					className="admin-text-input"
					value={input.headerColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-headerText-colorPicker"}
				name="headerText-colorPicker"
				className="admin-text-input"
				label={I("Header text color")}
			>
				<ColorPicker id="headerTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"headerTextColor")}}
					selected={configState.headerTextColor}
				/>
				<ReadOnlyTextField
					id={"headerTextColor"}
					name="headerTextColor"
					className="admin-text-input"
					value={input.headerTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-main-colorPicker"}
				name="main-colorPicker"
				className="admin-text-input"
				label={I("Main body color")}
			>
				<ColorPicker id="mainColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"mainColor")}}
					selected={configState.mainColor}
				/>
				<ReadOnlyTextField
					id={"mainColor"}
					name="mainColor"
					className="admin-text-input"
					value={input.mainColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-chatConversationBgColor-colorPicker"}
				name="chatConversationBgColor-colorPicker"
				className="admin-text-input"
				label={secondaryBgColorLabel}
			>
				<ColorPicker id="chatConversationBgColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"chatConversationBgColor")}}
					selected={configState.chatConversationBgColor}
				/>
				<ReadOnlyTextField
					id={"chatConversationBgColor"}
					name="chatConversationBgColor"
					className="admin-text-input"
					value={input.chatConversationBgColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-warningText-colorPicker"}
				name="headerBorder-colorPicker"
				className="admin-text-input"
				label={I("Warning text color")}
				helperTxt={I("Text that appears on input form validation")}
			>
				<ColorPicker id="warningTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"warningTextColor")}}
					selected={configState.warningTextColor}
				/>
				<ReadOnlyTextField
					id={"warningTextColor"}
					name="warningTextColor"
					className="admin-text-input"
					value={input.warningTextColor}
				/>
			</ReadOnlyTextField>
			<ReadOnlyTextField
				id={"adminChat-footerText-colorPicker"}
				name="footerText-colorPicker"
				className="admin-text-input"
				label={I("Footer text color")}
			>
				<ColorPicker id="footerTextColorPicker"
					onChangeColor={(c)=>{onChangeColor(c,"footerTextColor")}}
					selected={configState.footerTextColor}
				/>
				<ReadOnlyTextField
					id={"footerTextColor"}
					name="footerTextColor"
					className="admin-text-input"
					value={input.footerTextColor}
				/>
			</ReadOnlyTextField>
			<ClickableLableWithHelper
				className="reset-default"
				childrenClass="reset-button"
				label={I("Reset to default")}
				helperTxt={I("Reset all color configs to default value")}
				onClick={() => { onResetDefault() }}
			/>
		</div>
	)
}

const VoiceCustomTextConfig = ({input, onHandleTextInputChange, onHandleTextInputBlur, warning }) => {
	return 	(
		<div className="config-wrapper text">
			<TextInputRow
					id={"adminChat-textTitle"}
					name="textTitle"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Widget Header title")}
					value={input.textTitle || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textInputName"}
					name="textInputName"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Name input")}
					value={input.textInputName || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textInputEmail"}
					name="textInputEmail"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Email input")}
					value={input.textInputEmail || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textInputPhone"}
					name="textInputPhone"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Phone input")}
					value={input.textInputPhone || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textInputQuestion"}
					name="textInputQuestion"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for Question input")}
					value={input.textInputQuestion || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textEULA"}
					name="textEULAVoice"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for EULA")}
					value={input.textEULAVoice || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textMsgToCall"}
					name="textMsgToCall"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Text for Message to call")}
					value={input.textMsgToCall || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("Label for date selection.Eg. 'Schedule date and time for a call:'")}
			/>
			<TextInputRow
					id={"adminChat-textBtnSend"}
					name="textBtnSend"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for send button")}
					value={input.textBtnSend || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textBtnCancel"}
					name="textBtnCancel"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for cancel button")}
					value={input.textBtnCancel || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
			/>
			<TextInputRow
					id={"adminChat-textDateError"}
					name="textDateError"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Text for Date error message")}
					value={input.textDateError || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("Error message for callback date selection. Usually shown if the date selected is outside of working hours. Eg. 'Please select another date or time'")}
			/>
			<TextInputRow
					id={"adminChat-textMsgSendSuccess"}
					name="textMsgSendSuccess"
					className="admin-text-input"
					overrideClassName="select-row"
					label={I("Label for sent form")}
					value={input.textMsgSendSuccess || ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("Text showing after the user submitted the form. Eg. 'You have requested a callback through phone number at {SCHEDULE_TIME}, we will reach you at the appointed time.'")}
			/>
		</div>
	)
}

const VoiceCustomFeatureConfig = ({input, onHandleTextInputChange, onHandleTextInputBlur, onHandleChangeData, onToggleSwitch, warning, featureAllowVidCall }) => {
	return (
		<div className="config-wrapper chat-features">
			<TextInputRow
				id={"adminChat-logo"}
				name="logo"
				className="admin-text-input"
				label={I("Widget logo")}
				value={input.logo || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				overrideClassName="select-row"
				helperTxt={I("Logo is shown at the top left hand side of the widget. If it starts with '<' then it is assumed to be class icon (e.g: < i class='icon-example' />), otherwise it is treated as an image and will be put in <img src=\"{logo}\">. Eg. 'https://chat-logo.example.com'")}
			/>
			<TextInputRow
				id={"adminChat-chatZIndex"}
				name="voiceZIndex"
				className="admin-text-input"
				overrideClassName="select-row"
				label={I("Z-index")}
				value={input.voiceZIndex || ""}
				onChange={onHandleTextInputChange}
				onBlur={onHandleTextInputBlur}
				warning={warning}
				helperTxt={I("A value to represent the widget's relative appearance among other elements in the same page on your website. e.g: '999999'")}
			/>
			<SelectInputRow
				hidden={false}
				id={"adminChat-widgetPosition"}
				name={"widgetPosition"}
				label={I('Widget position')}
				className={"admin-select-review-option"}
				option={FAQ_WIDGET_POSITION_OPTION}
				value={input.widgetPosition}
				onSelect={onHandleChangeData}
			/>
			<TextInputRow
					id={"adminChat-bottomPosition"}
					name="bottomPosition"
					type={"number"}
					className="admin-text-input"
					label={I(`Widget bottom position`)}
					value={input.bottomPosition || "0"}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("Widget bottom position in pixels. Eg. '0'")}
			/>
			<TextInputRow
					id={"adminChat-leftPosition"}
					name="leftPosition"
					type={"number"}
					className="admin-text-input"
					label={I(`Widget left position`)}
					value={input.leftPosition || "0"}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("Widget left position in pixels. Only applicable for 'Bottom Left' position. Eg. '0'")}
			/>
			<TextInputRow
					id={"adminChat-rightPosition"}
					name="rightPosition"
					type={"number"}
					className="admin-text-input"
					label={I(`Widget right position`)}
					value={input.rightPosition || "0"}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={warning}
					helperTxt={I("Widget right position in pixels. Only applicable for 'Bottom Right' position. Eg. '0'")}
			/>
			<ToggleSwitch
					id={"askEULA"}
					name={"askEULA"}
					data-qa-id="askEULAVoice"
					label={I("Ask EULA")}
					checked={input.askEULA}
					onClick={onToggleSwitch}
					listWrap={false}
					className="admin-text-input"
			/>
		</div>
	)
}

//Both Chat & FAQ
export class WidgetConfigForm extends PureComponent {
	constructor(props) {
		super(props);
		this.handleToggleArea = this.handleToggleArea.bind(this);
		this.handleSelectArea = this.handleSelectArea.bind(this);
		this.handleDataChange = this.handleDataChange.bind(this);
		this.handleToggleLibrary = this.handleToggleLibrary.bind(this);
		this.handleSelectLibrary = this.handleSelectLibrary.bind(this);
		this.handleAvatarCrop = this.handleAvatarCrop.bind(this);
		this.handleAvatarClose = this.handleAvatarClose.bind(this);
		this.handleAvatarRemove = this.handleAvatarRemove.bind(this);
		this.state = {
			showArea: false,
			showLibrary: false,
			inputConfigs: this.props.input,
			activeTab: "1",
			isExpanded: false, 
		};
	}
	handleExpandToggle = () => {
		this.setState({ isExpanded: !this.state.isExpanded });
	  };
	  
	handleSelectArea(val) {
		this.props.onHandleChangeData(val, "activeAreas");
	}
	handleToggleArea() {
		this.setState({showArea: !this.state.showArea});
	}
	handleDataChange(value, field) {
		this.props.onHandleChangeData(value, field, this.props.view);
	}
	handleColorPicker = (color, field) => {
		this.props.onChangeAdminInput(field, color, this.props.view);
		let obj = {};
		obj[field] = color;
		this.setState(obj);
	}
	handleResetDefault = () => {
		this.props.onChangeAdminInput("resetWidget", "", this.props.view);
	}
	handleSelectLibrary(val) {
		this.props.onHandleChangeData(val, "activeLibraries");
	}
	handleToggleLibrary() {
		this.setState({showLibrary: !this.state.showLibrary});
	}
	toggleTab = (tab) => {
		this.setState({
			activeTab: tab
		})
	}
	componentDidUpdate(prevProps){
		if(prevProps.input !== this.props.input) {
			this.setState({
				inputConfigs: this.props.input
			});
		}
	}
	handleAvatarCrop(preview) {
		this.props.onHandleChangeData(preview, "proactiveImageURL");
	}
	handleAvatarRemove() {
		this.props.onHandleChangeData("", "proactiveImageURL");
	}
	handleAvatarClose() {
	}
	render() {
		const {
				view
				, input
				, langSrc
				, areaList
				, knowledgeBaseList
				, onHandleChangeData
				, onHandleTextInputBlur
				, onHandleTextInputChange
				, onHandleRemoveAvatar
				, channels
				, allChannels
			} = this.props
			, nameField = "name"
			, constantData = A_FORM_DATA[view]
			;
		let helperTxt
			, areaWarning
			, libraryWarning
			, featureAllowVidCall = features["chat.video-call"]
			, currAreaList = JSON.parse(JSON.stringify(areaList))
			;
		if (constantData) {
			contentLabel = constantData.label;
			helperTxt = constantData.helperTxt;
		}
		const activeTab = this.state.activeTab;
		let CustomAppearanceConfig = ChatCustomAppearanceConfig;
		let CustomTextConfig = ChatCustomTextConfig;
		let CustomFeatureConfig = ChatCustomFeatureConfig;
		// let tabs = ['Preview'];
		let BasicConfig = ChatBasicConfig;
		let widgetClass = "chat";
		let chatChannels = Object.entries(allChannels).map(e => e[1]);
		chatChannels.forEach((item, i) => {
			item.id = i + 1;
		  });
		if(this.props.view === M_FAQWIDGETDL ) {
			BasicConfig = FaqBasicConfig;
			CustomAppearanceConfig = FaqCustomAppearanceConfig;
			CustomTextConfig = FaqCustomTextConfig;
			CustomFeatureConfig = FaqCustomFeatureConfig;
			widgetClass = "faq"
		} else if ( this.props.view === M_VOICEWIDGETDL ) {
			BasicConfig = VoiceBasicConfig;
			CustomAppearanceConfig = VoiceCustomAppearanceConfig;
			CustomTextConfig = VoiceCustomTextConfig;
			CustomFeatureConfig = VoiceCustomFeatureConfig;
			widgetClass = "chat"
		}
		if (!input.widgetId) {
			tabs = ['Preview']; // If the widgetId is not present, this implies the information is yet to be saved. 
			input.widgetId = uuidv4() // Generate a new widgetId.
		} else {
			tabs = ['Preview', 'Code']; // When widgetId exists, add 'Code' tab to the configuration options.
		}
		return (
			<div
			className="admin-one-form widget-label-block"
			style={{
				overflow: 'hidden',
				maxHeight: '100%',
				maxWidth: '100%',
				boxSizing: 'border-box',
			}}
			>
				<div style={{ display: this.state.isExpanded ? 'none' : 'block' }}>
				 
				<BasicConfig
					input={input}
					onHandleTextInputChange={onHandleTextInputChange}
					onHandleTextInputBlur={onHandleTextInputBlur}
					areaWarning={areaWarning}
					warning={this.props.warning}
					currAreaList={currAreaList}
					onHandleSelectArea={this.handleSelectArea}
					onHandleToggleArea={this.handleToggleArea}
					onHandleDataChange={this.handleDataChange}
					showArea={this.state.showArea}
					libraryWarning={libraryWarning}
					knowledgeBaseList={knowledgeBaseList}
					idFields={idNameSmall}
					onHandleSelectLibrary={this.handleSelectLibrary}
					onHandleToggleLibrary={this.handleToggleLibrary}
					showLibrary={this.state.showLibrary}
					onSelectData={onHandleChangeData}
				/>
				<hr hidden={!input.customize} className="section-separator" />
				</div>
				{input.customize &&
					<section
					className={`${widgetClass} customize-configs`}
					style={{
						height: this.state.isExpanded ? '100%' : '380px',
					  width: this.state.isExpanded ? '100%' : 'initial', 
					  overflow: this.state.isExpanded ? 'visible' : 'hidden',
					}}
				  >
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Nav tabs style={{ flexGrow: 1 }}>
							<OneNavItem
							active={activeTab === "1"}
							onClick={this.toggleTab}
							tabId={"1"}
							text={I("Appearance")}
							/>
							<OneNavItem
							active={activeTab === "2"}
							onClick={this.toggleTab}
							tabId={"2"}
							text={I("Text")}
							/>
							<OneNavItem
							active={activeTab === "3"}
							onClick={this.toggleTab}
							tabId={"3"}
							text={(this.props.view === M_VOICEWIDGETDL || this.props.view === M_FAQWIDGETDL) ? I("Features") : I("Chat features")}
							/>
							{this.props.view === M_CHATWIDGETDL && (
							<OneNavItem
								active={activeTab === "4"}
								onClick={this.toggleTab}
								tabId={"4"}
								text={I("Proactive features")}
							/>
							)}
						</Nav>
						<TableIconicButton
							type="button"
							className="btn bg-transparent action expand"
							iconClass={this.state.isExpanded ? "icon-collapse" : "icon-expand"}
							iconPosition="right"
							iconSize="14px"
							buttonTxtColor={centionTitleGrey}
							onClick={this.handleExpandToggle}
						/>
					</div>

						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<CustomAppearanceConfig
									input={input}
									onChangeColor={this.handleColorPicker}
									configState={this.state.inputConfigs}
									onResetDefault={this.handleResetDefault}
								/>
							</TabPane>
							<TabPane tabId="2">
								<CustomTextConfig
									input={input}
									onHandleTextInputChange={onHandleTextInputChange}
									onHandleTextInputBlur={onHandleTextInputBlur}
									warning={this.props.warning}
								/>
							</TabPane>
							<TabPane tabId="3">
								<CustomFeatureConfig
									input={input}
									onHandleTextInputChange={onHandleTextInputChange}
									onHandleTextInputBlur={onHandleTextInputBlur}
									onToggleSwitch={this.handleDataChange}
									featureAllowVidCall={featureAllowVidCall}
									onHandleChangeData={onHandleChangeData}
									warning={this.props.warning}
									channels={channels}
									allChannels={chatChannels}
								/>
							</TabPane>
							<TabPane tabId="4">
								<ChatProactiveFeatureConfig
									input={input}
									configState={this.state.inputConfigs}
									onHandleTextInputChange={onHandleTextInputChange}
									onHandleTextInputBlur={onHandleTextInputBlur}
									onToggleSwitch={this.handleDataChange}
									onChangeColor={this.handleColorPicker}
									onHandleChangeData={onHandleChangeData}
									onHandleAvatarCrop={this.handleAvatarCrop}
									proactiveAvatarPreview={this.state.proactiveAvatarPreview}
									onHandleAvatarClose={this.handleAvatarClose}
									onHandleRemoveAvatar={this.handleAvatarRemove}
									warning={this.props.warning}
								/>
							</TabPane>
						</TabContent>
					</section>
				}
			</div>
		);
	}
}

//Carousel that holds widget frame/template as slides

export const WidgetCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  let items= props.skinSet;
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.page}
      >
		{item.view}
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
	  interval={false}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

//folder grid carousel (todo: refactor to re-usable carousel component)
export const FolderCarousel = ({
	selectedPage
	, setIndex
	, items
}) => {

	const [activeIndex, setActiveIndex] = useState(selectedPage);
	const [animating, setAnimating] = useState(false);

	const next = () => {
		if (animating) return;
		const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
	  setActiveIndex(nextIndex);
	  setTimeout(() => {
		setIndex(nextIndex)
	}, 500);
	}

	const previous = () => {
	  if (animating) return;
	  const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
	  setActiveIndex(nextIndex);
	setTimeout(() => {
		setIndex(nextIndex)
	}, 500);
	}

	const goToIndex = (newIndex) => {
	  if (animating) return;
	  setActiveIndex(newIndex);
	  setTimeout(() => {
		  setIndex(newIndex)
	  }, 500);
	}

	const exiting = () => {
		setAnimating(true)
	}
	const exited = () => {
		setAnimating(false)
	}

	const slides = items.map((item) => {
	  return (
		<CarouselItem
		  enableTouch={true}
		  onExiting={exiting}
		  onExited={exited}
		  key={item.page}
		>
		  {item.view}
		</CarouselItem>
	  );
	});

	let showFull  = items.length > 1

	return (
	  <Carousel
		activeIndex={activeIndex}
		next={next}
		previous={previous}
		interval={false}
		className={showFull ? "full" : "semi"}
	  >
		{slides}
		{showFull &&
			<Fragment>
				<CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
				<CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
				<CarouselControl direction="next" directionText="Next" onClickHandler={next} />
			</Fragment>
		}
	  </Carousel>
	);
  }

const widgetScriptURL = process.env.CLOUDFRONT_URL + '/chat/js/widget.js';
const jQueryScriptURL = process.env.CLOUDFRONT_URL + '/chat/js/jquery.min.js';
export class ChatWidgetCodePreviewForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selected: 'Preview',
			widgetHashCode: "",
			jQueryHashCode: ""
		};
	}
	componentDidMount() {
		/* if(this.props.input.integrityAttr) {
			this.createIntegrityValue();
		} */
	}
	componentDidUpdate(prevProps) {
		/* if (this.props.input.integrityAttr !== prevProps.input.integrityAttr) {
			if(this.props.input.integrityAttr) {
				this.createIntegrityValue();
			}
		} */
	}
	createIntegrityValue = () => {
		createIntegrityAttr(widgetScriptURL)
			.then(hash => {
				this.setState({ widgetHashCode: hash });
			})
			.catch(error => {
				console.error(error);
				this.setState({ widgetHashCode: '' });
			});

		createIntegrityAttr(jQueryScriptURL)
			.then(hash => {
				this.setState({ jQueryHashCode: hash });
			})
			.catch(error => {
				console.error(error);
				this.setState({ jQueryHashCode: '' });
			});
	}
	setSelected = (tab) => {
		this.setState({ selected: tab });
		if(this.props.onChangeTab) {
			this.props.onChangeTab(tab);
		}
	}
	renderSelectedAreas = () => {
		let selectedArea = this.props.input.activeAreas + "";
		if (selectedArea.split(',').length > 1){
			let areaMap = new Map();
			$.each(selectedArea.split(','), (i,v) => {
				areaMap.set(v, v);
			});
			let selectedAreas = [];
			$.each(this.props.areaList, (i,v) => {
				if(v.Areas){
					$.each(v.Areas, (j,area) => {
						if(areaMap.has(area.Id.toString())){
							selectedAreas.push({id : area.Id, name: area.Name});
						}
					});
				}
			});

			return JSON.stringify(selectedAreas);
		}
		return selectedArea
	}
	isArraySelectedAreas = () => {
		let selectedArea = this.props.input.activeAreas + "";
		if (selectedArea.split(',').length > 1){
			return true;
		}
		return false;
	}
	renderCode = () => {
		const { input } = this.props;
		const { widgetHashCode, jQueryHashCode } = this.state;
		const REMOVE_JQUERY_FROM_CHAT = true;
		let preview = "";
		if(REMOVE_JQUERY_FROM_CHAT) {
			preview = `<script type="text/javascript">var CentionBaseURL = "${input.baseURL}"; var CloudFrontURL = "${WidgetCloudFrontURL}";</script>\n`
		} else {
			preview = `<script type="text/javascript" src="${jQueryScriptURL}"`
			/* if (input.integrityAttr) {
				preview += ` integrity="${jQueryHashCode}" crossorigin="anonymous"`
			} */
			preview += `></script>\n`
				+ `<script type="text/javascript">var CentionBaseURL = "${input.baseURL}";</script>\n`
				+ `<script type="text/javascript">var CloudFrontURL = "${WidgetCloudFrontURL}";</script>\n`;
		}

		if (input.customize && input.adminChatEnableVideoChat) {
			preview += `<div id="cention-video-container"></div>\n`;
		}

		preview += `<script src="${widgetScriptURL}" type="text/javascript"`

		/* if (input.integrityAttr) {
			preview += ` integrity="${widgetHashCode}" crossorigin="anonymous"`
		} */

		preview += `></script>\n`
			+ `<div id="cention-chat-container"></div>\n`
			+ `<script type="text/javascript">\n`
			+ `if (typeof CentionChat !== "undefined") {\n`
			+ `  CentionChat("cention-chat-container", {\n`
			+ `    widgetId: "${this.props.input.widgetId}",\n`;
		if (input.adminChatMaximize || input.adminChatMinimize || input.adminWidgetLoad) {
			preview += `    hooks: {\n`;
			if (input.adminChatMaximize) {
				preview += `      onChatMaximize: function() {\n`
					+ `        // This function is called when the chat is maximized\n`
					+ `      }\n`
			}
			if (input.adminChatMinimize) {
				preview += `      onChatMinimize: function() {\n`
					+ `        // This function is called when the chat is minimized\n`
					+ `      }\n`
			}
			if (input.adminWidgetLoad) {
				preview += `      onWidgetLoaded: function() {\n`
					+ `        // This function is called when the chat widget is loaded\n`
					+ `      }\n`
			}
			preview += `    }\n`
		}
		preview += `  });\n`
			+ `}\n`
			+ `</script>\n`;

		return preview
	}
	render() {
		const {
				input
				, agent
				, hide
			} = this.props
			;

		const { widgetHashCode, jQueryHashCode } = this.state;

		let renderSelectedAreas = this.renderSelectedAreas(),
			showAreas = this.isArraySelectedAreas(),
			optionItems = "";

		if (showAreas){
			let selectedAreas = JSON.parse(renderSelectedAreas);
			optionItems = selectedAreas.map(
				({ id, name }) => <option key={id} value={name} index={name}>{name}</option>
			)
		}
		let showCodePreview = true;
		/* if (input.integrityAttr && (widgetHashCode || jQueryHashCode) === "") {
			showCodePreview = false;
		} */
		return (
			<Fragment>
				<div 
						style={{
							marginTop: this.state.selected === 'Preview' ? "-80px" : "-40px"

						  }}
						>
				<TabNav tabs={tabs} selected={this.state.selected} setSelected={this.setSelected} hide={hide}>
					<Tab isSelected={this.state.selected === 'Preview'}>
						<div className="admin-one-form widget-label-block">
							<WidgetPreview input={input} agent={agent} type="chat"/>
						</div>
					</Tab>
					<Tab isSelected={ this.state.selected === 'Code' }>
						<div className="admin-one-form widget-label-block">
							<div id="cell-preview-body-chat-widget">
								{
									showCodePreview &&
										<textarea data-qa-id={"textarea-field-input-preview"} className={"preview-body"} value={sanitizeHtml(this.renderCode())} disabled={true}/>
								}
							</div>
						</div>
					</Tab>
				</TabNav>
				</div>
			</Fragment>
		);
	}
}

export class FaqWidgetCodePreviewForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selected: 'Preview',
			widgetHashCode: "",
			jQueryHashCode: ""
		};
	}
	componentDidMount() {
		/* if (this.props.input.integrityAttr) {
			this.createIntegrityValue();
		} */
	}
	componentDidUpdate(prevProps) {
		/* if (this.props.input.integrityAttr !== prevProps.input.integrityAttr) {
			if (this.props.input.integrityAttr) {
				this.createIntegrityValue();
			}
		} */
	}
	createIntegrityValue = () => {
		createIntegrityAttr(widgetScriptURL)
			.then(hash => {
				this.setState({ widgetHashCode: hash });
			})
			.catch(error => {
				console.error(error);
				this.setState({ widgetHashCode: '' });
			});

		createIntegrityAttr(jQueryScriptURL)
			.then(hash => {
				this.setState({ jQueryHashCode: hash });
			})
			.catch(error => {
				console.error(error);
				this.setState({ jQueryHashCode: '' });
			});
	}
	setSelected = (tab) => {
		this.setState({ selected: tab });
		if(this.props.onChangeTab) {
			this.props.onChangeTab(tab);
		}
	}
	renderSelectedAreas = () => {
		let selectedArea = this.props.input.activeAreas + "";
		if (selectedArea.split(',').length > 1){
			let areaMap = new Map();
			$.each(selectedArea.split(','), (i,v) => {
				areaMap.set(v, v);
			});
			let selectedAreas = [];
			$.each(this.props.areaList, (i,v) => {
				if(v.Areas){
					$.each(v.Areas, (j,area) => {
						if(areaMap.has(area.Id.toString())){
							selectedAreas.push({id : area.Id, name: area.Name});
						}
					});
				}
			});

			return JSON.stringify(selectedAreas);
		}
		return selectedArea
	}
	isArraySelectedAreas = () => {
		let selectedArea = this.props.input.activeAreas + "";
		if (selectedArea.split(',').length > 1){
			return true;
		}
		return false;
	}
	renderCode = () => {
		const { input } = this.props;
		const { widgetHashCode, jQueryHashCode } = this.state;

		let preview = `<script type="text/javascript" src="${jQueryScriptURL}"`

		/* if (input.integrityAttr) {
			preview += ` integrity="${jQueryHashCode}" crossorigin="anonymous"`
		} */

		preview += `></script>\n`
			+ `<script type="text/javascript">var CentionBaseURL = "${input.baseURLFAQ}";</script>\n`
			+ `<script type="text/javascript">var CloudFrontURL = "${WidgetCloudFrontURL}";</script>\n`;

		if (input.customize && input.adminFaqEnableVideoChatFAQ) {
			preview += `<div id="cention-video-container"></div>\n`;
		}

		preview += `<script type="text/javascript"> var FAQ_WITH_CHAT = ${input.faqWithChat}; </script>\n`
			+ `<script src="${widgetScriptURL}" type="text/javascript"`

		/* if (input.integrityAttr) {
			preview += ` integrity="${widgetHashCode}" crossorigin="anonymous"`
		} */

		preview += `></script>\n`
			+ `<div id="cention-faq-container"></div>\n`
			+ `<script type="text/javascript">\n`
			+ `if(typeof CentionFAQ !== "undefined") {\n`
			+ `	CentionFAQ("cention-faq-container", {\n`
			+ `	widgetId: "${this.props.input.widgetId}",\n`
			+ `	libraryId : [${input.activeLibraries}],\n`
			+ `	});\n`
			+ `}\n`
			+ `</script>\n`;

		return preview
	}
	render() {
		const { input
			, hide
			, agent
		} = this.props
			;
		const { widgetHashCode, jQueryHashCode } = this.state;
		let showCodePreview = true;
		/* if (input.integrityAttr && (widgetHashCode || jQueryHashCode) === "") {
			showCodePreview = false;
		} */
		return (
			<Fragment>
				<TabNav tabs={['Preview', 'Code']} selected={ this.state.selected } setSelected={ this.setSelected } hide={hide}>
					<Tab isSelected={ this.state.selected === 'Preview' }>
						<div className="admin-one-form widget-label-block">
							<WidgetPreview input={input} agent={agent} previewType="faq"/>
						</div>
					</Tab>
					<Tab isSelected={ this.state.selected === 'Code' }>
						<div className="admin-one-form widget-label-block">
							<div id="cell-preview-body-faq-widget">
								{
									showCodePreview &&
										<textarea data-qa-id={"textarea-field-input-preview"} className={"preview-body"} value={sanitizeHtml(this.renderCode())} disabled={true}/>
								}
							</div>
						</div>
					</Tab>
				</TabNav>
			</Fragment>
		);
	}
}

const voiceWidgerScriptURL = process.env.CLOUDFRONT_URL + '/chat/js/voice-widget.min.js';
export class VoiceWidgetCodePreviewForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selected: 'Preview',
			widgetHashCode: "",
		};
	}
	componentDidMount() {
		/* if(this.props.input.integrityAttr) {
			this.createIntegrityValue();
		} */
	}
	componentDidUpdate(prevProps) {
		/* if (this.props.input.integrityAttr !== prevProps.input.integrityAttr) {
			if(this.props.input.integrityAttr) {
				this.createIntegrityValue();
			}
		} */
	}
	createIntegrityValue = () => {
		createIntegrityAttr(voiceWidgerScriptURL)
			.then(hash => {
				this.setState({ widgetHashCode: hash });
			})
			.catch(error => {
				console.error(error);
				this.setState({ widgetHashCode: '' });
			});
	}
	setSelected = (tab) => {
		this.setState({ selected: tab });
		if(this.props.onChangeTab) {
			this.props.onChangeTab(tab);
		}
	}
	renderSelectedAreas = () => {
		let selectedArea = this.props.input.activeAreas + "";
		if (selectedArea.split(',').length > 1){
			let areaMap = new Map();
			$.each(selectedArea.split(','), (i,v) => {
				areaMap.set(v, v);
			});
			let selectedAreas = [];
			$.each(this.props.areaList, (i,v) => {
				if(v.Areas){
					$.each(v.Areas, (j,area) => {
						if(areaMap.has(area.Id.toString())){
							selectedAreas.push({id : area.Id, name: area.Name});
						}
					});
				}
			});

			return JSON.stringify(selectedAreas);
		}
		return selectedArea
	}
	isArraySelectedAreas = () => {
		let selectedArea = this.props.input.activeAreas + "";
		if (selectedArea.split(',').length > 1){
			return true;
		}
		return false;
	}
	renderCode = () => {
		const { input } = this.props;
		const { widgetHashCode } = this.state;
		let preview = `<script type="text/javascript">var CentionBaseURL = "${input.baseURL}";</script>\n`
			+ `<script type="text/javascript">var CloudFrontURL = "${WidgetCloudFrontURL}";</script>\n`
			+ `<script async src="${voiceWidgerScriptURL}" type="text/javascript"`

		/* if (input.integrityAttr) {
			preview += ` integrity="${widgetHashCode}" crossorigin="anonymous"`
		} */

		preview += `></script>\n`
			+ `<div id="cention-voice-container"></div>\n`
			+ `<script type="text/javascript">\n`
			+ `var CentionVoice = {\n`
			+ ` areaId : ${this.renderSelectedAreas()}\n`
			+ `}\n`
			+ `</script>\n`;

		return preview;
	}
	render() {
		const {
				input
				, hide
			} = this.props
			;

		const { widgetHashCode } = this.state;

		let renderSelectedAreas = this.renderSelectedAreas(),
			showAreas = this.isArraySelectedAreas(),
			optionItems = "";

		if (showAreas) {
			let selectedAreas = JSON.parse(renderSelectedAreas);
			optionItems = selectedAreas.map(
				({ id, name }) => <option key={id} value={name} index={name}>{name}</option>
			)
		}
		let showCodePreview = true;
		/* if (input.integrityAttr && widgetHashCode === "") {
			showCodePreview = false;
		} */
		return (
			<Fragment>
				<TabNav tabs={['Preview', 'Code']} selected={ this.state.selected } setSelected={ this.setSelected } hide={hide}>
					<Tab isSelected={ this.state.selected === 'Preview' }>
						<div className="admin-one-form widget-label-block">
							<WidgetPreview input={input} previewType="callback"/>
						</div>
					</Tab>		
		<Tab isSelected={ this.state.selected === 'Code' }>
		<div className="admin-one-form widget-label-block">
			<div id="cell-preview-body-voice-widget">
				{
					showCodePreview &&
						<textarea data-qa-id={"textarea-field-input-preview"} className={"preview-body"} value={sanitizeHtml(this.renderCode())} disabled={true}/>
				}
			</div>
		</div>
	</Tab>	
				</TabNav>
			</Fragment>
		);
	}
}
